import React, { useState, useMemo } from 'react'
import { FormGroup, Label, Button } from 'reactstrap'
import Select, { GroupBase, MultiValue, MultiValueRemoveProps } from 'react-select'
import GenericDrawer from 'components/GenericDrawer/GenericDrawer'
import styles from './FilterDrawer.module.scss'
import { Filter, FilterAdditionalData, FilterTypes } from './FilterDrawer.config'
import ActiveFilters from 'components/ActiveFilters/ActiveFilters'
import Range from 'UI/Range/Range'
import Toggle from 'UI/Toggle/Toggle'
import { getSplitFilterMapping } from 'components/ActiveFilters/utils'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { format } from 'date-fns'
import TextField from '@mui/material/TextField'
import Icon from 'components/Icon'
import Tooltip from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Stack from '@mui/material/Stack'
import GenericText from 'components/common/GenericText'
import { useTheme } from '@mui/material/styles'
import Checkbox from 'components/CustomCheckboxButtonItem/CustomCheckboxButtonItem'
import { selectorClassNames } from './utils'

const closeIconUrl = require('./icons/close.svg')

interface FilterDrawerProps {
  open: boolean
  onClose: () => void
  activeFilters: Filter[]
  setActiveFilters: React.Dispatch<React.SetStateAction<Filter[]>>
  filtersInitialState: Filter[]
  filterOptions: Record<string, { value: string; label: string }[]>
  filterAdditionalData?: FilterAdditionalData[]
  shouldUseRawRangeValues?: boolean
}

const selectorComponents = {
  IndicatorSeparator: () => null,
  MultiValueRemove: (
    state: MultiValueRemoveProps<
      {
        value: string
        label: string
      },
      true,
      GroupBase<{
        value: string
        label: string
      }>
    >,
  ) => <img className={styles.multiValueRemove} src={closeIconUrl} alt='close' onClick={state.innerProps.onClick} />,
  ClearIndicator: () => null,
}

const FilterDrawer = ({
  open,
  onClose,
  activeFilters,
  setActiveFilters,
  filtersInitialState,
  filterOptions,
  filterAdditionalData,
  shouldUseRawRangeValues,
}: FilterDrawerProps) => {
  const [filters, setFilters] = useState<Filter[]>(filtersInitialState)
  const [isApplyFilterDisabled, setIsApplyFilterDisabled] = useState(true)

  const theme = useTheme()

  const handleApplyFilter = () => {
    setActiveFilters(filters.filter(filter => filter?.value.length > 0))
  }

  const handleFilterChange = (
    name: string,
    selectedOptions: MultiValue<{
      value: string
      label: string
    }>,
  ) => {
    const updatedFilters = filters.map(filter =>
      filter.name === name ? { ...filter, value: selectedOptions.map(option => option.value) } : filter,
    )

    setFilters(updatedFilters)
    updateApplyFilterButtonState(updatedFilters)
  }

  const handleRangeFilterChange = (name: string, type: 'min' | 'max', value: string) => {
    const updatedFilters = filters.map(filter => {
      const newValue = [...filter.value]
      const index = type === 'min' ? 0 : 1
      newValue[index] = value
      return filter.name === name ? { ...filter, value: newValue } : filter
    })

    setFilters(updatedFilters)
    updateApplyFilterButtonState(updatedFilters)
  }

  const handleBooleanFilterChange = (name: string, value: boolean) => {
    const updatedFilters = filters.map(filter => {
      const newValue = [...filter.value]
      newValue[0] = value ? 'true' : 'false'
      return filter.name === name ? { ...filter, value: newValue } : filter
    })

    setFilters(updatedFilters)
    updateApplyFilterButtonState(updatedFilters)
  }

  const handleDateFilterChange = (name: string, date: Date) => {
    const updatedFilters = filters.map(filter =>
      filter.name === name ? { ...filter, value: [date.toISOString()] } : filter,
    )

    setFilters(updatedFilters)
    updateApplyFilterButtonState(updatedFilters)
  }

  const handleCheckboxesFilterChange = (name: string, boolValue: boolean, stringValue: string) => {
    const updatedFilters = filters.map(filter => {
      const newValue = [...filter.value]
      if (boolValue) {
        newValue.push(stringValue)
      } else {
        const removedIndex = newValue.findIndex(val => val === stringValue)
        newValue.splice(removedIndex, 1)
      }

      return filter.name === name ? { ...filter, value: newValue } : filter
    })

    setFilters(updatedFilters)
    updateApplyFilterButtonState(updatedFilters)
  }

  const updateApplyFilterButtonState = (updatedFilters: Filter[]) => {
    const isDisabled = updatedFilters.every(filter => filter.value.length === 0)
    setIsApplyFilterDisabled(isDisabled)
  }

  const displayedFilters = useMemo(() => {
    const { rangeFilters, booleanFilters, defaultFilters } = getSplitFilterMapping(activeFilters)

    return [...rangeFilters, ...booleanFilters, ...defaultFilters]
  }, [activeFilters])

  const clearFilters = () => {
    setFilters(filtersInitialState)
    setActiveFilters([])
  }

  return (
    <GenericDrawer
      open={open}
      onClose={onClose}
      title='Filters'
      iconName='filterBlue'
      optionalAction={clearFilters}
      optionalActionLabel='Clear All'
    >
      <div className={styles.filterDrawerContainer}>
        <div className={styles.mainContent}>
          {displayedFilters.length > 0 && (
            <div className={styles.activeFilters}>
              <p>Active Filters:</p>
              <ActiveFilters
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                styles={styles}
                filterAdditionalData={filterAdditionalData}
                filterOptions={filterOptions}
                setFilters={setFilters}
                shouldUseRawRangeValues={shouldUseRawRangeValues}
              />
            </div>
          )}
          {filters.map((filter, index) => {
            const additionalData = filterAdditionalData?.find(el => el.name === filter.name)

            if (filter.type === FilterTypes.ROBUST_RANGE) {
              return (
                <Range
                  rangeType={FilterTypes.ROBUST_RANGE}
                  title={filter.label || ''}
                  minLabel='Min.'
                  maxLabel='Max.'
                  minValue={filter.value?.[0]}
                  maxValue={filter.value?.[1]}
                  onMinChange={value => handleRangeFilterChange(filter.name, 'min', value)}
                  onMaxChange={value => handleRangeFilterChange(filter.name, 'max', value)}
                  key={index}
                  minLimit={typeof additionalData?.minLimit === 'number' ? additionalData.minLimit : undefined}
                  maxLimit={typeof additionalData?.maxLimit === 'number' ? additionalData.maxLimit : undefined}
                />
              )
            }
            if (filter.type === FilterTypes.RANGE) {
              const minValue = filterOptions[filter?.name][0]?.value[0] || 0
              const maxValue = filterOptions[filter?.name][0]?.value[1] || 0
              const marks = Array.from({ length: +maxValue + 1 }, (_, index) => ({
                value: index,
                label: index.toString(),
              }))
              return (
                <Range
                  rangeType={FilterTypes.RANGE}
                  title={filter.label || ''}
                  minLimit={minValue as number}
                  maxLimit={maxValue as number}
                  minValue={filter.value?.[0]}
                  maxValue={filter.value?.[1]}
                  onMinChange={value => handleRangeFilterChange(filter.name, 'min', value)}
                  onMaxChange={value => handleRangeFilterChange(filter.name, 'max', value)}
                  key={index}
                  step={filter.step}
                  marks={marks}
                />
              )
            }
            if (filter.type === FilterTypes.BOOL) {
              const additionalData = filterAdditionalData?.find(additionalData => additionalData.name === filter.name)
              const tooltipTitle = additionalData?.tooltipText
              return (
                <div key={index} className={styles.boolTypeContainer}>
                  <Stack direction='row' gap='10px' alignItems='center'>
                    <div className={styles.label}>{filter.label}</div>
                    {tooltipTitle && (
                      <Tooltip
                        arrow
                        title={
                          <GenericText fontFamily='Dosis' fontSize='14px'>
                            {tooltipTitle || ''}
                          </GenericText>
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              border: '1px solid #DAD8DF',
                              borderRadius: '8px',
                              backgroundColor: 'white',
                              color: theme.palette.textColor.main,
                              p: 1,
                              '& .MuiTooltip-arrow': {
                                color: 'common.white',
                              },
                            },
                          },
                        }}
                      >
                        <InfoOutlinedIcon sx={{ fontSize: '16px', color: theme.palette.primary.main }} />
                      </Tooltip>
                    )}
                  </Stack>

                  <Toggle
                    checked={filter.value?.[0] === 'true'}
                    key={index}
                    onChange={e => handleBooleanFilterChange(filter.name, e.target.checked)}
                  />
                </div>
              )
            }
            if (filter.type === FilterTypes.DATE_BIT) {
              const selectedDate = filter.value[0] ? new Date(filter.value[0]) : new Date()

              return (
                <div key={index} className={styles.datePickerContainer}>
                  <Label className={styles.label} for={filter.name}>
                    {filter.label}
                  </Label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date: Date) => handleDateFilterChange(filter.name, date)}
                    dateFormat='MMMM, yyyy'
                    showMonthYearPicker
                    customInput={
                      <TextField
                        className={styles.dateBitInput}
                        variant='outlined'
                        fullWidth
                        value={format(selectedDate, 'MMMM, yyyy')}
                        InputProps={{
                          endAdornment: <Icon name='filter-calendar' size='medium' />,
                        }}
                      />
                    }
                  />
                </div>
              )
            }
            if (filter.type === FilterTypes.YEARS_RANGE_SELECTOR) {
              const list = filter?.list?.map(str => ({ value: str, label: str })) || []

              return (
                <div key={index} className={styles.yearsRangeSelectorTypeContainer}>
                  <div className={styles.label}>{filter.label}</div>
                  <div className={styles.selectorsWrapper}>
                    <Select
                      className={styles.selectInput}
                      unstyled
                      options={list}
                      components={{
                        IndicatorSeparator: () => null,
                        ClearIndicator: () => null,
                      }}
                      classNames={selectorClassNames}
                      value={list.find(el => el.value === filter?.value?.[0])}
                      onChange={option => {
                        handleRangeFilterChange(filter.name, 'min', option?.value || '')
                      }}
                    />
                    <Select
                      className={styles.selectInput}
                      unstyled
                      options={list}
                      components={{
                        IndicatorSeparator: () => null,
                        ClearIndicator: () => null,
                      }}
                      classNames={selectorClassNames}
                      value={list.find(el => el.value === filter?.value?.[1])}
                      onChange={option => {
                        handleRangeFilterChange(filter.name, 'max', option?.value || '')
                      }}
                    />
                  </div>
                </div>
              )
            }
            if (filter.type === FilterTypes.CHECKBOXES) {
              return (
                <div key={index} className={styles.checkboxesContainer}>
                  <div key={index} className={styles.checkboxesTypeContainer}>
                    <div className={styles.label}>{filter.label}</div>
                    <div className={styles.list}>
                      {filterOptions[filter?.name]?.map(option => (
                        <div key={option.label} className={styles.checkboxesWrapper}>
                          <Checkbox
                            checked={filter.value.includes(option.value)}
                            onChange={e => handleCheckboxesFilterChange(filter?.name, e.target.checked, option.value)}
                          />
                          <div className={styles.checkLabel}>{option.label}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )
            }
            return (
              <FormGroup key={filter?.name} className={styles.formGroup}>
                <Label className={styles.label} for={filter?.name}>
                  {filter?.label}
                </Label>
                <Select
                  className={styles.selectInput}
                  isMulti
                  unstyled
                  options={filterOptions[filter?.name]}
                  components={selectorComponents}
                  classNames={selectorClassNames}
                  value={(filter?.value || []).map(value => ({ value, label: value }))}
                  onChange={selectedOptions => handleFilterChange(filter?.name, selectedOptions)}
                />
              </FormGroup>
            )
          })}
        </div>

        <Button
          className={styles.applyButton}
          color='primary'
          onClick={() => {
            handleApplyFilter()
            onClose()
          }}
          disabled={isApplyFilterDisabled}
        >
          Apply Filter
        </Button>
      </div>
    </GenericDrawer>
  )
}

export default FilterDrawer
