import { ReactNode } from 'react'
import { Associates, PLATFORMS } from 'store/verticals/types'

export interface VerticalCardProps {
  platform: PLATFORMS
  profileName: string
  profileImage: string
  profileUrl: string
  children: ReactNode
  associates?: Associates[]
  areAllCardsCollapsed?: boolean
  isCollapsible?: boolean
  handleToggleCards?: () => void
  cardHeaderBgColor?: string
  cardHeadingLinkColor?: string
  customPlatformImg?: string
}

export enum CaretTypes {
  Expand = 'expand',
  Collapse = 'collapse',
}
