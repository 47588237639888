import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import TableText from 'components/common/TableText'
import { NOT_AVAILABLE } from 'utils/constants'
import { SxProps } from '@mui/material'

const ColoredDot = ({ sx }: { sx: SxProps }) => {
  return <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', ...sx }}></Box>
}

interface ColorStatusCellProps {
  label: string
  color: string
}

const ColorStatusCell = ({ label, color }: ColorStatusCellProps) => (
  <Stack direction='row' alignItems='center' gap={1}>
    <ColoredDot sx={{ bgcolor: color }} />
    <TableText>{label || NOT_AVAILABLE}</TableText>
  </Stack>
)

export default ColorStatusCell
