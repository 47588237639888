import { Filter, FilterTypes } from 'components/FilterDrawer/FilterDrawer.config'
import { SellerCartCheckStatus } from 'containers/VerticalPage/common/types'
import { enumToObject } from 'utils/enumToObject'

interface FilterConfig extends Filter {
  customOptionsLabels?: Record<string, string>
  isNested?: boolean
  vertical?: string
}

export const filtersInitialState: FilterConfig[] = [
  {
    type: FilterTypes.CHIPS,
    name: 'filters.registryStatus.keyword',
    label: 'Seller Registration Status',
    isNested: false,
    value: [],
  },
  {
    type: FilterTypes.CHIPS,
    name: 'filters.cartCheckStatus.keyword',
    label: 'Collects Sales Taxes',
    isNested: false,
    value: [],
    customOptionsLabels: enumToObject(SellerCartCheckStatus),
  },
  {
    type: FilterTypes.DATE_BIT,
    name: 'filters.firstActivity',
    label: 'First Activity',
    value: [],
    vertical: 'ecomSalesTaxes',
    isNested: false,
  },
  {
    type: FilterTypes.CHIPS,
    name: 'filters.shopsCategories.keyword',
    label: 'Shop Category',
    isNested: false,
    value: [],
  },
  {
    type: FilterTypes.BOOL,
    name: 'filters.physicalNexusBusiness',
    label: 'Physical Nexus: Address',
    activeFilterLabel: 'Physical Nexus Address Only',
    isNested: false,
    value: [],
  },
  {
    type: FilterTypes.BOOL,
    name: 'filters.physicalNexusEmployees',
    label: 'Physical Nexus: Employees',
    activeFilterLabel: 'Physical Nexus Employees Only',
    isNested: false,
    value: [],
  },
]
