import React, { useEffect, useMemo, useState } from 'react'
import { VerticalCardProps } from 'containers/VerticalPage/common/VerticalCard/types'
import ExpandedVerticalCard from 'containers/VerticalPage/common/VerticalCard/ExpandedVerticalCard'
import CollapsedVerticalCard from 'containers/VerticalPage/common/VerticalCard/CollapsedVerticalCard'
import { normalizeUrlProtocol } from 'containers/VerticalPage/common/utils'

const VerticalCard = ({
  profileName,
  profileUrl,
  platform,
  profileImage,
  associates,
  areAllCardsCollapsed = false,
  handleToggleCards,
  isCollapsible,
  customPlatformImg,
  cardHeaderBgColor,
  cardHeadingLinkColor,
  children,
}: VerticalCardProps) => {
  const platformName = platform?.toLocaleLowerCase()
  const [isCollapsed, setIsCollapsed] = useState(false)

  useEffect(() => {
    setIsCollapsed(areAllCardsCollapsed)
  }, [areAllCardsCollapsed])

  const href = useMemo(() => {
    if (profileUrl) return normalizeUrlProtocol(profileUrl)
    if (profileName) return normalizeUrlProtocol(`https://${profileName}`)
    return ''
  }, [profileUrl, profileName])

  return isCollapsed && handleToggleCards ? (
    <CollapsedVerticalCard
      platformName={platformName}
      headingLink={href}
      profileImage={profileImage}
      profileName={profileName}
      handleToggleCards={handleToggleCards}
      cardHeaderBgColor={cardHeaderBgColor}
      cardHeadingLinkColor={cardHeadingLinkColor}
      customPlatformImg={customPlatformImg}
    />
  ) : (
    <ExpandedVerticalCard
      platformName={platformName}
      associates={associates}
      profileName={profileName}
      profileImage={profileImage}
      href={href}
      handleToggleCards={handleToggleCards}
      isCollapsible={isCollapsible}
      cardHeaderBgColor={cardHeaderBgColor}
      customPlatformImg={customPlatformImg}
      caretColor={cardHeadingLinkColor}
    >
      {children}
    </ExpandedVerticalCard>
  )
}

export default VerticalCard
