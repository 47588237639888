import React, { FC } from 'react'
import { getRegistryData } from './util'
import { IRegistryIds } from 'store/verticals/types'
import { NOT_AVAILABLE } from 'utils/constants'
import TableText from 'components/common/TableText'
import Stack from '@mui/material/Stack'
import Tooltip from 'components/Tooltip'
import styles from './styles'

interface RegistryNumberColumn {
  registry: { ids: IRegistryIds[] }[]
}

const RegistryNumberColumn: FC<RegistryNumberColumn> = ({ registry }) => {
  const data = getRegistryData(registry)
  const firstItem = data[0]
  const allValuesCount = data.reduce((total, item) => total + item.values.length, 0)

  return (
    <TableText>
      {firstItem?.values?.[0] || NOT_AVAILABLE}
      {data.length - 1 > 0 && (
        <Tooltip
          theme='light'
          html={
            <Stack sx={styles.tooltipContainer}>
              {data
                .map(el => ({ ...el, values: el.values.filter(val => val !== firstItem?.values?.[0]) }))
                .filter(el => el.values.length > 0)
                .map((el, i) => (
                  <Stack key={i} sx={styles.rowContainer}>
                    <Stack sx={styles.label}>{el.label}</Stack>
                    <Stack>
                      {el.values.map(value => (
                        <Stack sx={styles.value} key={value}>
                          {value}
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                ))}
            </Stack>
          }
        >
          <span style={{ fontWeight: 'bold' }}> +{allValuesCount - 1}</span>
        </Tooltip>
      )}
    </TableText>
  )
}

export default RegistryNumberColumn
