import React from 'react'
import { CaretTypes, VerticalCardProps } from 'containers/VerticalPage/common/VerticalCard/types'
import GenericCard from 'components/common/GenericCard'
import GenericCardHeader from 'components/common/GenericCardHeader'
import VerticalCardHeadingLink from 'containers/VerticalPage/common/VerticalCard/VerticalCardHeadingLink'
import VerticalCardAvatar from 'containers/VerticalPage/common/VerticalCard/VerticalCardAvatar'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { usePlatformIcon } from 'containers/VerticalPage/common/VerticalCard/usePlatformIcon'
import styles from './styles'
import CardToggleCaret from 'containers/VerticalPage/common/VerticalCard/CardToggleCaret'

interface ExpandedVerticalCardProps extends Omit<VerticalCardProps, 'platform' | 'profileUrl'> {
  platformName: string
  href: string
  isCollapsible?: boolean
  handleToggleCards?: () => void
  cardHeaderBgColor?: string
  customPlatformImg?: string
  caretColor?: string
}

const ExpandedVerticalCard = ({
  platformName,
  profileName,
  profileImage,
  children,
  href,
  isCollapsible,
  handleToggleCards,
  cardHeaderBgColor,
  customPlatformImg,
  caretColor,
}: ExpandedVerticalCardProps) => {
  const theme = useTheme()
  const platformIcon = usePlatformIcon(platformName)

  return (
    <GenericCard sx={{ height: '100%' }}>
      <GenericCardHeader
        sx={{
          bgcolor: cardHeaderBgColor || theme.palette.platforms[platformName] || theme.palette.secondary.main,
        }}
        title={
          <Stack alignItems='center' justifyContent='space-between' flexDirection='row'>
            <Stack sx={styles.HeaderContainer}>
              {platformIcon && (
                <img style={styles.PlatformIcon} src={customPlatformImg || platformIcon} alt='Platform' />
              )}
            </Stack>
            {isCollapsible && handleToggleCards && (
              <CardToggleCaret
                caretType={CaretTypes.Collapse}
                handleClick={handleToggleCards}
                caretColor={caretColor}
              />
            )}
          </Stack>
        }
      />
      <CardContent sx={styles.ContentContainer}>
        <Box position='relative'>
          <Stack sx={styles.TopContent}>
            <VerticalCardAvatar profileImage={profileImage} sx={styles.Avatar} />
            <VerticalCardHeadingLink href={href} profileName={profileName} sx={styles.HeadingLink} />
          </Stack>
        </Box>
        {children}
      </CardContent>
    </GenericCard>
  )
}

export default ExpandedVerticalCard
