// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductsByShop-module__productsByShops--cR_zh .ProductsByShop-module__copyBtn--rd8gw{border:none;background-color:rgba(0,0,0,0);padding:0}.ProductsByShop-module__productsByShops--cR_zh .ProductsByShop-module__copyBtn--rd8gw .ProductsByShop-module__copyIcon--MGrnS{color:#7e8ba6 !important;fill:#7e8ba6 !important}", "",{"version":3,"sources":["webpack://./app/containers/VerticalPage/ecommerce-extended/ProductsByShopTooltip/ProductsByShop.module.scss"],"names":[],"mappings":"AACE,sFACE,WAAA,CACA,8BAAA,CACA,SAAA,CAEA,8HACE,wBAAA,CACA,uBAAA","sourcesContent":[".productsByShops {\n  .copyBtn {\n    border: none;\n    background-color: transparent;\n    padding: 0;\n\n    .copyIcon {\n      color: #7e8ba6 !important;\n      fill: #7e8ba6 !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productsByShops": "ProductsByShop-module__productsByShops--cR_zh",
	"copyBtn": "ProductsByShop-module__copyBtn--rd8gw",
	"copyIcon": "ProductsByShop-module__copyIcon--MGrnS"
};
export default ___CSS_LOADER_EXPORT___;
