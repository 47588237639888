import React, { useEffect, useRef, useState, FC, useContext } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import SearchBar from '../SearchBar/SearchBar'
import Button from 'components/Button'
import Icon from 'components/Icon'
import TabScrolledContent from '../TabScrolledContent/TabScrolledContent'
import WhiteBoxWrapper from '../WhiteBoxWrapper/WhiteBoxWrapper'
import modularStyles from './Employees.scss'
import { useEmployeesQuery } from 'containers/VerticalPage/company-overview/EmployeesTab/useEmployeesQuery'
import { CurrentZone } from 'utils/types'
import { useSelector } from 'react-redux'
import { selectCurrentZone } from 'store/global'
import useVerticalSearchAndSort from 'hooks/useVerticalSearchAndSort'
import { Filter, FilterTypes } from 'components/FilterDrawer/FilterDrawer.config'
import FilterDrawer from 'components/FilterDrawer/FilterDrawer'
import { useFilterOptionsQuery } from 'hooks/useFilterOptionsQuery'
import { filtersInitialState } from './filter.config'
import { useDebounce } from 'hooks/useDebounce'
import { MRT_RowVirtualizer, MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import useEmployeesTableOptions from 'containers/VerticalPage/company-overview/EmployeesTab/useEmployeesTableOptions'
import { useTableInfiniteScroll } from 'containers/VerticalPage/common/useTableInfiniteScroll'
import { useCountDocuments } from 'hooks/useCountDocuments'
import useEmployeesColumns from 'containers/VerticalPage/company-overview/EmployeesTab/useEmployeesColumns'
import styles from './styles'
import { useParams } from 'react-router-dom'

const noRegistryFoundUrl = require('../SellerRegistry/icons/noRegistryFound.svg')

interface IEmployees {
  tableHeight: number
  employeeIds: number[]
  setEmployeeIds: (ids: number[]) => void
}

const Employees: FC<IEmployees> = ({ tableHeight, employeeIds, setEmployeeIds }) => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const { entityId: caseId } = useParams<{ entityId: string }>()

  const { searchValue, searchValueDebounced, setSearchValue } = useVerticalSearchAndSort()

  const [totalDBRowCount, setTotalDBRowCount] = useState(0)
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
  const [activeFilters, setActiveFilters] = useState<Filter[]>([])

  const activeFiltersDebounced = useDebounce(activeFilters, 500)

  const { data: docsCountData } = useCountDocuments({
    verticalIndex: currentZone.ecommerceExtendedEmployeesIndices,
    currentQueryKey: 'ecommerce-extended-overview-activities-key',
  })

  const { verticalDocumentActivity, isFetching, fetchNextPage, totalFetched } = useEmployeesQuery({
    caseId,
    zoneActivityIndices: currentZone.ecommerceExtendedEmployeesIndices,
    pageSize: 10,
    searchValue: searchValueDebounced,
    filters: activeFiltersDebounced,
    sort: [],
  })

  const { filterOptions } = useFilterOptionsQuery({
    verticalIndices: currentZone?.ecommerceExtendedEmployeesIndices,
    currentQueryKey: 'ecommerce-extended-overview-filter-options-key',
    filtersInitialState,
  })

  const { handleScroll } = useTableInfiniteScroll({
    pageSize: 10,
    totalFetched,
    totalDBRowCount,
    isFetching,
    tableContainerRef,
    fetchNextPage,
  })

  useEffect(() => {
    setTotalDBRowCount(docsCountData?.totalCount || 0)
  }, [docsCountData])

  useEffect(() => {
    const employeeIdsFilter = {
      type: FilterTypes.SELECT,
      name: 'employeeId',
      label: 'Employee Id',
      value: employeeIds,
      keyword: false,
    }

    setActiveFilters(prevFilters => [
      ...prevFilters,
      { ...employeeIdsFilter, value: Array.from(new Set(employeeIds)).map(String) },
    ])

    return () => {
      setEmployeeIds([])
    }
  }, [employeeIds])

  const handleFilterButtonClick = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen)
  }

  const columns = useEmployeesColumns([
    'Name',
    'Location',
    'JobDepartment',
    'JobRole',
    'DateFrom',
    'DateTo',
    'Period',
    'JobDesc',
    'Canonical Url',
  ])
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null)

  const tableOptions = useEmployeesTableOptions(
    columns,
    verticalDocumentActivity,
    isFetching,
    handleScroll,
    tableHeight,
    rowVirtualizerInstanceRef,
    styles.muiTableContainerSxProps,
  )

  const table = useMaterialReactTable(tableOptions)

  return (
    <TabScrolledContent sxProps={{ height: '100%' }}>
      <WhiteBoxWrapper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={styles.title}>Employees List</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder='Search by name, url, job role or description keyword'
              />
              {/* Comment for now until we will know how exact it should work */}
              {/* <Select
                sxButton={styles.searchBySelectorButton}
                list={mockList}
                selected={0}
                onChange={id => console.log('selected', id)}
              >
                Search by columns
              </Select> */}
            </Box>
            <Button className={modularStyles.actionsBarButton} onClick={handleFilterButtonClick}>
              <Icon size='standard' name='filterBlue' />
            </Button>
            {filterOptions && (
              <FilterDrawer
                open={isFilterDrawerOpen}
                onClose={() => setIsFilterDrawerOpen(false)}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                filtersInitialState={filtersInitialState}
                filterOptions={filterOptions}
              />
            )}
          </Box>
        </Box>
        {totalFetched === 0 && !isFetching ? (
          <Stack sx={{ textAlign: 'center', pt: '100px' }}>Couldn't find employees</Stack>
        ) : verticalDocumentActivity ? (
          <>
            {verticalDocumentActivity.length > 0 ? (
              <MaterialReactTable table={table} />
            ) : (
              <WhiteBoxWrapper>
                <Stack flexDirection='row' alignItems='center' justifyContent='center' gap='8px'>
                  <Box component='img' width={32} height={32} src={noRegistryFoundUrl} alt='No Employees found' />
                  <Typography sx={styles.title}>No Registry Found</Typography>
                </Stack>
              </WhiteBoxWrapper>
            )}
          </>
        ) : (
          <Stack alignItems='center' sx={{ width: '100%' }}>
            <CircularProgress size={40} thickness={4} sx={{ margin: '55px' }} />
          </Stack>
        )}
      </WhiteBoxWrapper>
    </TabScrolledContent>
  )
}

export default Employees
