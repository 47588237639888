import React from 'react'
import Avatar from '@mui/material/Avatar'
import CardMedia from '@mui/material/CardMedia'
import { SxProps } from '@mui/material'

const avatarIcon = require('containers/VerticalPage/nft/assets/avatar.png')

interface VerticalCardAvatarProps {
  profileImage: string
  sx: SxProps
}

const VerticalCardAvatar = ({ profileImage, sx }: VerticalCardAvatarProps) => {
  return (
    <Avatar sx={sx}>
      <CardMedia component='img' image={profileImage || avatarIcon} alt='Avatar' />
    </Avatar>
  )
}

export default VerticalCardAvatar
