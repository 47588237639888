import { useQuery } from '@tanstack/react-query'
import { Filter } from 'components/FilterDrawer/FilterDrawer.config'
import { fetchEcomExtendedFilterOptions } from 'services/verticalsTablesApi'

interface UseFilterOptionsQuery {
  verticalIndices: string
  currentQueryKey: string
  filtersInitialState: Filter[]
}

export const useFilterOptionsQuery = ({
  verticalIndices,
  currentQueryKey,
  filtersInitialState,
}: UseFilterOptionsQuery) => {
  const { data: filterOptions, isLoading } = useQuery({
    queryKey: [currentQueryKey, verticalIndices, filtersInitialState],
    queryFn: async () => {
      if (!verticalIndices) return null
      return await fetchEcomExtendedFilterOptions(verticalIndices, filtersInitialState)
    },
    refetchOnWindowFocus: false,
  })

  return {
    filterOptions,
    isLoading,
  }
}
