import React from 'react'

const CollapseIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.5026 1.66669C17.7526 1.66669 17.9193 1.75002 18.0859 1.91669C18.4193 2.25002 18.4193 2.75002 18.0859 3.08335L13.9193 7.16669C13.5859 7.50002 13.0859 7.50002 12.7526 7.16669C12.4193 6.83335 12.4193 6.33335 12.7526 6.00002L16.9193 1.91669C17.0859 1.66669 17.2526 1.66669 17.5026 1.66669Z'
        fill='#2257C5'
      />
      <path
        d='M13.3385 2.00002C13.8385 2.00002 14.1719 2.33335 14.1719 2.83335V5.75002H17.0885C17.5885 5.75002 17.9219 6.08335 17.9219 6.58335C17.9219 7.08335 17.5885 7.41669 17.0885 7.41669L13.3385 7.41669C12.8385 7.41669 12.5052 7.08335 12.5052 6.58335V2.83335C12.5052 2.33335 12.8385 2.00002 13.3385 2.00002Z'
        fill='#2257C5'
      />
      <path
        d='M13.4115 12.5C13.6615 12.5 13.8281 12.5834 13.9948 12.75L18.0781 16.9167C18.4115 17.25 18.4115 17.75 18.0781 18.0834C17.7448 18.4167 17.2448 18.4167 16.9115 18.0834L12.8281 13.9167C12.4948 13.5834 12.4948 13.0834 12.8281 12.75C12.9948 12.5834 13.1615 12.5 13.4115 12.5Z'
        fill='#2257C5'
      />
      <path
        d='M13.4089 12.5H17.1589C17.6589 12.5 17.9922 12.8334 17.9922 13.3334C17.9922 13.8334 17.6589 14.1667 17.1589 14.1667H14.2422V17.0834C14.2422 17.5834 13.9089 17.9167 13.4089 17.9167C12.9089 17.9167 12.5755 17.5834 12.5755 17.0834V13.3334C12.5755 12.8334 12.9089 12.5 13.4089 12.5Z'
        fill='#2257C5'
      />
      <path
        d='M6.66667 12.5C6.91667 12.5 7.08333 12.5834 7.25 12.75C7.58333 13.0834 7.58333 13.5834 7.25 13.9167L3.08333 18.0834C2.75 18.4167 2.25 18.4167 1.91666 18.0834C1.58333 17.75 1.58333 17.25 1.91666 16.9167L6.08333 12.75C6.25 12.5834 6.41667 12.5 6.66667 12.5Z'
        fill='#2257C5'
      />
      <path
        d='M2.91667 12.5L6.66667 12.5C7.16667 12.5 7.5 12.8334 7.5 13.3334L7.5 17.0834C7.5 17.5834 7.16667 17.9167 6.66667 17.9167C6.16667 17.9167 5.83333 17.5834 5.83333 17.0834V14.1667H2.91667C2.41667 14.1667 2.08333 13.8334 2.08333 13.3334C2.08333 12.8334 2.41667 12.5 2.91667 12.5Z'
        fill='#2257C5'
      />
      <path
        d='M2.5 1.66669C2.75 1.66669 2.91667 1.75002 3.08333 1.91669L7.25 6.00002C7.58333 6.33335 7.58333 6.83335 7.25 7.16669C6.91667 7.50002 6.41667 7.50002 6.08333 7.16669L1.91666 3.08335C1.58333 2.75002 1.58333 2.25002 1.91666 1.91669C2.08333 1.66669 2.25 1.66669 2.5 1.66669Z'
        fill='#2257C5'
      />
      <path
        d='M6.66667 2.00002C7.16667 2.00002 7.5 2.33335 7.5 2.83335L7.5 6.58335C7.5 7.08335 7.16667 7.41669 6.66667 7.41669L2.91667 7.41669C2.41667 7.41669 2.08333 7.08335 2.08333 6.58335C2.08333 6.08335 2.41667 5.75002 2.91667 5.75002L5.83333 5.75002L5.83333 2.83335C5.83333 2.33335 6.16667 2.00002 6.66667 2.00002Z'
        fill='#2257C5'
      />
    </svg>
  )
}

export default CollapseIcon
