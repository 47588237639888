// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Employees__actionsBarButton--adfsI{width:40px;height:40px;display:flex;justify-content:center;align-items:center;border:1px solid #d1d1d6;box-shadow:none}", "",{"version":3,"sources":["webpack://./app/containers/VerticalPage/ecommerce-extended/Employees/Employees.scss"],"names":[],"mappings":"AAAA,oCACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,wBAAA,CACA,eAAA","sourcesContent":[".actionsBarButton {\n  width: 40px;\n  height: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 1px solid #d1d1d6;\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsBarButton": "Employees__actionsBarButton--adfsI"
};
export default ___CSS_LOADER_EXPORT___;
