import React, { useMemo } from 'react'
import { FORMAT_HANDLERS, SalesTaxCartStatuses, TooltipData } from 'containers/VerticalPage/common/types'
import { RevenueSourceOverview } from 'store/verticals/types'
import { formatDate } from 'containers/VerticalPage/common/ActivityOverviewCard/utils'
import { NOT_AVAILABLE } from 'utils/constants'
import { formatNumber } from 'utils/formatters'
import { FormattedNumber } from 'react-intl'
import { getAbbreviatedNumber } from 'components/Income/Income'

interface ProcessedRevenueSource {
  key: string
  value: string | number
  tooltip: TooltipData
  processedKey?: string
}

interface UseProcessRevenueSourcesProps {
  revenueSources: RevenueSourceOverview
  includeFields?: string[]
  customOrder?: string[]
}

export const useProcessRevenueSources = ({
  revenueSources,
  includeFields,
  customOrder,
}: UseProcessRevenueSourcesProps) => {
  const processedRevenueSources = useMemo(() => {
    if (!revenueSources) return []

    const includedRevenueSources = Object.entries(revenueSources).filter(
      ([key]) => !includeFields || includeFields.includes(key),
    )

    const orderedRevenueSources = customOrder
      ? sortByCustomOrder(includedRevenueSources, customOrder)
      : sortByLocale(includedRevenueSources)

    const currentRevenueSources: ProcessedRevenueSource[] = orderedRevenueSources.map(
      ([overviewKey, overviewValue]) => ({
        key: processRevenueSourceKey(overviewKey),
        value: processRevenueSourceValue(overviewKey, overviewValue),
        tooltip: tooltipHandlers?.[overviewKey]?.(overviewValue) || null,
      }),
    )

    return currentRevenueSources
  }, [revenueSources])

  return processedRevenueSources
}

function sortByCustomOrder(sources: [string, any][], customOrder: string[]) {
  return sources.sort((a, b) =>
    customOrder.indexOf(a[0]) === -1 ? 1 : customOrder.indexOf(a[0]) - customOrder.indexOf(b?.[0]) || 0,
  )
}

function sortByLocale(sources: [string, any][]) {
  return sources.sort((a, b) => a[0].localeCompare(a[0]))
}

const revenueSourceTooltips: Record<string, TooltipData> = {
  TAX_SECTION_DETECTED: {
    text: `IVIX has identified that the seller has integrated a plugin 
            to facilitate tax collection at the checkout page but 
            has opted not to activate this feature!`,
    iconSx: { color: '#de1c1c' },
  },
}

const salesStatusesMap = {
  TAX_DETECTED: SalesTaxCartStatuses.TAX_DETECTED,
  TAX_SECTION_NOT_DETECTED: SalesTaxCartStatuses.TAX_SECTION_NOT_DETECTED,
  TAX_SECTION_DETECTED: SalesTaxCartStatuses.TAX_SECTION_DETECTED,
  ERROR_FILL_IN_FORM: SalesTaxCartStatuses.ERROR_FILL_IN_FORM,
  FAILURE: SalesTaxCartStatuses.FAILURE,
}

const revenueSourceOverviewValueHandlers = {
  [FORMAT_HANDLERS.NumberValue]: (value: number) => value,
  [FORMAT_HANDLERS.FirstActivity]: (value: string) => formatDate(value),
  [FORMAT_HANDLERS.LastActivity]: (value: string) => formatDate(value),
  [FORMAT_HANDLERS.AveragePrice]: (value: number) => {
    return (
      <FormattedNumber
        style='currency'
        minimumFractionDigits={2}
        value={getAbbreviatedNumber(value).finalNumber}
        currency='USD'
      />
    )
  },
  [FORMAT_HANDLERS.CartCheckStatus]: (value: { status: string }) => {
    return salesStatusesMap[value?.status] || NOT_AVAILABLE
  },
}

const revenueSourceKeys = {
  [FORMAT_HANDLERS.CartCheckStatus]: 'Cart Check Status',
  [FORMAT_HANDLERS.AveragePrice]: 'Average Product Price',
  [FORMAT_HANDLERS.ProductCount]: 'Listing Count',
}

const revenueSourceOverviewKeyHandlers = {
  [FORMAT_HANDLERS.CartCheckStatus]: (key: string) => revenueSourceKeys?.[FORMAT_HANDLERS.CartCheckStatus] || key,
  [FORMAT_HANDLERS.ProductCount]: (key: string) => revenueSourceKeys?.[FORMAT_HANDLERS.ProductCount] || key,
}

const tooltipHandlers = {
  [FORMAT_HANDLERS.CartCheckStatus]: (value: { status: string; products: { link: string; title: string }[] }) => {
    return revenueSourceTooltips?.[value?.status] || null
  },
}

function processRevenueSourceKey(overviewKey: string) {
  const processedKey = revenueSourceOverviewKeyHandlers?.[overviewKey]?.(overviewKey) || overviewKey
  return processedKey
}

function processRevenueSourceValue(overviewKey: string, overviewValue: any) {
  const isDateKey =
    overviewKey === (FORMAT_HANDLERS.FirstActivity as keyof RevenueSourceOverview) ||
    overviewKey === (FORMAT_HANDLERS.LastActivity as keyof RevenueSourceOverview)

  const isTextKey = overviewKey === (FORMAT_HANDLERS.CartCheckStatus as keyof RevenueSourceOverview)

  const isCurrencyKey = overviewKey === (FORMAT_HANDLERS.AveragePrice as keyof RevenueSourceOverview)

  const currentFormatHandler =
    revenueSourceOverviewValueHandlers[
      isDateKey || isTextKey || isCurrencyKey ? overviewKey : FORMAT_HANDLERS.NumberValue
    ]

  const processedValue = currentFormatHandler(
    isDateKey || isTextKey || isCurrencyKey
      ? overviewValue
      : formatNumber(overviewValue, null, { style: overviewKey === 'totalRevenue' ? 'currency' : 'decimal' }),
  )

  return processedValue
}
