import React, { useMemo, useState } from 'react'
import { SingleValue } from 'react-select'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CartCheckProduct } from 'store/verticals/types'
import ShopSelect from 'containers/VerticalPage/ecommerce-extended/ProductsByShopTooltip/ShopSelect'
import { ShopData, ShopSelectOption } from 'containers/VerticalPage/ecommerce-extended/ProductsByShopTooltip/types'
import ProductItem from 'containers/VerticalPage/ecommerce-extended/ProductsByShopTooltip/ProductItem'
import styles from './ProductsByShop.module.scss'
import sxStyles from 'containers/VerticalPage/ecommerce-extended/ProductsByShopTooltip/styles'

interface ProductsByShopsProps {
  shopsData: ShopData[]
}

const ProductsByShops = ({ shopsData }: ProductsByShopsProps) => {
  const [currentProducts, setCurrentProducts] = useState<CartCheckProduct[]>([])

  const handleSelectShopChange = (option: SingleValue<ShopSelectOption>) => {
    const products = shopsData.find(data => data.shopName === option?.value)?.products || []
    setCurrentProducts(products)
  }

  const options = useMemo(() => {
    return shopsData.map(data => ({ value: data.shopName, label: data.shopName }))
  }, [shopsData])

  return (
    <Stack sx={sxStyles.ProductByShop} className={styles.productsByShops}>
      <Typography sx={sxStyles.TooltipTitle}>Checked Product Links</Typography>
      <ShopSelect options={options} currentProducts={currentProducts} handleSelectShopChange={handleSelectShopChange} />
      {currentProducts?.length ? (
        <Box component='ul' sx={sxStyles.ProductsList}>
          {currentProducts.map((product, index) => (
            <div key={product.title + index}>
              <ProductItem product={product} index={index} />
            </div>
          ))}
        </Box>
      ) : (
        <Typography sx={sxStyles.NoSelectedShopText}>Cannot find products.</Typography>
      )}
    </Stack>
  )
}

export default ProductsByShops
