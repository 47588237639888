import React, { useContext, useEffect, useState, FC } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { VerticalContext } from 'store/verticals/Context'
import ActivityOverviewCard from 'containers/VerticalPage/ecommerce-extended/ActivityOverviewCard/ActivityOverviewCard'
import VerticalCard from 'containers/VerticalPage/common/VerticalCard/VerticalCard'
import RevenueSourcesOverview from 'containers/VerticalPage/common/RevenueSourcesOverview/RevenueSourcesOverview'
import EntityCard from 'components/EntityCard/EntityCard'
import GenericCarousel from 'components/common/GenericCarousel'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import {
  ActivityOverviewCardLoader,
  EntityCardLoader,
  VerticalCarouselLoader,
} from 'containers/VerticalPage/common/Loaders'
import { selectCurrentZone } from 'store/global'
import type { CurrentZone } from 'utils/types'
import { PLATFORMS } from 'store/verticals/types'
import { useTheme } from '@mui/material/styles'
import { originalSourceToImageMap } from 'components/BusinessSource/specs'
import { defaultWebsiteIcon } from 'containers/VerticalPage/common/utils'
import styles from './styles'

const Content: FC = () => {
  const { entityId } = useParams<{ entityId: string }>()
  const { getVerticalEntity, verticalEntity, isCollapsedRevenuesCarousel, toggleRevenuesCarousel } =
    useContext(VerticalContext)
  const [isLoading, setIsLoading] = useState(false)
  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  const theme = useTheme()

  useEffect(() => {
    if (!entityId || !currentZone) return

    if (currentZone.ecommerceExtendedIndices) {
      const fetchPersonCardData = async () => {
        setIsLoading(true)
        await getVerticalEntity(entityId, currentZone.ecommerceExtendedIndices)
        setIsLoading(false)
      }
      fetchPersonCardData()
    }
  }, [currentZone, entityId])

  const handleToggleCards = () => {
    toggleRevenuesCarousel()
  }
  const cardConfig: Partial<
    Record<
      PLATFORMS,
      {
        revenueSourceFields: string[]
        cardHeaderBgColor?: string
        cardHeadingLinkColor?: string
        platformImg?: string
      }
    >
  > = {
    [PLATFORMS.SHOPIFY]: {
      revenueSourceFields: ['firstActivity', 'averagePrice', 'productCount', 'cartCheck'], // Order matter - will affect the order of rendering
      cardHeaderBgColor: theme.palette.platforms.shopWebsite,
      cardHeadingLinkColor: theme.palette.textColor.main,
      platformImg: originalSourceToImageMap[defaultWebsiteIcon],
    },
    [PLATFORMS.OTHER]: {
      revenueSourceFields: ['firstActivity', 'reviews', 'totalRevenue', 'cartCheck'], // Order matter - will affect the order of rendering
    },
  }

  const renderCarouselCards = () => {
    return verticalEntity?.revenueSources?.length ? (
      verticalEntity?.revenueSources?.map(source => {
        const isShopify = source?.platform.toLowerCase() === PLATFORMS.SHOPIFY
        const currentConfig = isShopify ? cardConfig[PLATFORMS.SHOPIFY] : cardConfig[PLATFORMS.OTHER]

        return (
          <Stack
            direction='row'
            key={source.profileUrl}
            sx={{
              justifyContent: 'space-between',
              mb: '0',
            }}
          >
            <VerticalCard
              platform={source.platform}
              profileName={source.profileName}
              profileImage={source.profileImageUrl}
              profileUrl={source.profileUrl}
              associates={source.associates}
              areAllCardsCollapsed={isCollapsedRevenuesCarousel}
              handleToggleCards={handleToggleCards}
              isCollapsible={true}
              cardHeaderBgColor={currentConfig?.cardHeaderBgColor}
              cardHeadingLinkColor={currentConfig?.cardHeadingLinkColor}
              customPlatformImg={currentConfig?.platformImg}
            >
              <RevenueSourcesOverview
                revenueSources={source.overview}
                includeFields={currentConfig?.revenueSourceFields}
                customOrder={currentConfig?.revenueSourceFields}
              />
            </VerticalCard>
          </Stack>
        )
      })
    ) : (
      <VerticalCarouselLoader />
    )
  }

  return (
    <Box sx={styles.ContentContainer}>
      {verticalEntity?.entityDetails ? (
        <EntityCard
          tin={verticalEntity.entityDetails.tin}
          name={verticalEntity.entityDetails.name}
          image={verticalEntity.entityDetails.image}
          profileUrls={verticalEntity.entityDetails.profileUrls}
          location={verticalEntity.entityDetails.location}
          birthday={verticalEntity.entityDetails.birthday}
        />
      ) : (
        isLoading && <EntityCardLoader />
      )}

      {verticalEntity?.overviewDetails ? (
        <ActivityOverviewCard overviewData={verticalEntity.overviewDetails} />
      ) : (
        isLoading && <ActivityOverviewCardLoader />
      )}

      {verticalEntity ? (
        <GenericCarousel
          slidesCount={verticalEntity?.revenueSources?.length || 0}
          isCollapsible
          isCollapsedCarousel={isCollapsedRevenuesCarousel}
          setIsCollapsedCarousel={toggleRevenuesCarousel}
        >
          {renderCarouselCards()}
        </GenericCarousel>
      ) : (
        isLoading && <VerticalCarouselLoader />
      )}
    </Box>
  )
}

export default Content
