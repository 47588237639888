import React from 'react'
import Button from 'components/common/Button'
import { CollapsibleCarouselButtons } from 'components/common/types'
import ExpandIcon from 'components/common/icons/ExpandIcon'
import CollapseIcon from 'components/common/icons/CollapseIcon'
import { useTheme } from '@mui/material/styles'

interface CollapsibleCarouselButtonProps {
  handleCollapseClick: () => void | undefined
  isCollapsedCarousel?: boolean
}

const CollapsibleCarouselButton = ({ isCollapsedCarousel, handleCollapseClick }: CollapsibleCarouselButtonProps) => {
  const theme = useTheme()

  const btnState = isCollapsedCarousel
    ? { label: CollapsibleCarouselButtons.Collapsed, icon: <ExpandIcon /> }
    : { label: CollapsibleCarouselButtons.Expanded, icon: <CollapseIcon /> }

  return (
    <Button
      label={btnState.label}
      svgIcon={btnState.icon}
      onClick={handleCollapseClick}
      style={{
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        textTransform: 'capitalize',
        border: 'none',
      }}
    />
  )
}

export default CollapsibleCarouselButton
