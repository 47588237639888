import React, { useEffect, useMemo, useState } from 'react'
import Alert from '@mui/material/Alert'
import { selectCurrentZone } from '../../store/global'
import NavigationHeader from 'components/NavigationHeader/NavigationHeader'
import GridLoader from 'components/GridLoader/GridLoader'
import TableActionsBars from 'components/verticals/TableActionsBar/TableActionsBar'

import { CurrentZone } from 'utils/types'

import useVerticalSearchAndSort from 'hooks/useVerticalSearchAndSort'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import messages from './messages'

import styles from './EcommerceExtendedTablePage.scss'
import { getUrlPageValues } from '../VerticalPage/common/utils'
import { ISelectedColumn } from 'components/verticals/VerticalTableBase/types'
import { MRT_ColumnDef } from 'material-react-table'
import { useEcommerceExtendedEmptyColumnsQuery } from 'hooks/useEmptyEcommerceExtendedColumnsQuery'
import { EcommerceExtendedEntityForTable, IEcommerceExtendedSummary } from './types'
import EcommerceExtendedTable from 'components/ecommerceExtended/EcommerceExtendedTable/EcommerceExtendedTable'
import { filtersInitialState } from 'containers/EcommerceExtendedTablePage/EcommerceExtended.config'
import { useDebounce } from 'hooks/useDebounce'
import { Filter, FilterAdditionalData } from 'components/FilterDrawer/FilterDrawer.config'
import { useFilterOptionsQuery } from 'containers/EcommerceExtendedTablePage/useFiltersOptionsQuery'
import { SellerCartCheckStatus } from 'containers/VerticalPage/common/types'
import { enumToObject } from 'utils/enumToObject'
import { useEcommerceExtendedOverviewQuery } from './useEcommerceExtendedOverviewQuery'
import EcommerceExtendedSummary from './EcommerceExtendedSummary'
import { useEcommerceExtendedTableQuery } from 'containers/EcommerceExtendedTablePage/useEcommerceExtendedTableQuery'

const csvFileName = 'EcommerceExtended'

const EcommerceExtendedTablePage = () => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)
  const [mergedColumns, setMergedColumns] = useState<MRT_ColumnDef<EcommerceExtendedEntityForTable>[]>([])
  const [selectedColumns, setSelectedColumns] = useState<ISelectedColumn[]>([])

  const [activeFilters, setActiveFilters] = useState<Filter[]>([])
  const [filterAdditionalData, setFilterAdditionalData] = useState<FilterAdditionalData[]>([])

  const activeFiltersDebounced = useDebounce(activeFilters, 500)

  // get the options as they are from the backend
  const { filterOptions } = useFilterOptionsQuery({
    verticalIndices: currentZone?.ecommerceExtendedIndices,
    currentQueryKey: 'ecommerce-extended-filter-options-key',
    filtersInitialState,
  })

  const { searchValue, searchValueDebounced, sorting, setSearchValue, setSorting } = useVerticalSearchAndSort()

  const { data: emptyColumns } = useEcommerceExtendedEmptyColumnsQuery({
    verticalIndices: currentZone?.ecommerceExtendedIndices,
    currentQueryKey: 'ecommerce-extended-entities-key',
  })

  // Fetch overview data
  const { data: overviewData } = useEcommerceExtendedOverviewQuery({
    verticalIndices: currentZone?.ecommerceExtendedIndices,
    currentQueryKey: 'ecommerce-extended-overview-key',
    searchValue: searchValueDebounced,
    filters: activeFiltersDebounced,
  })

  useEffect(() => {
    if (!filterOptions || !overviewData) return
    setFilterAdditionalData([
      { name: 'filters.cartCheckStatus.keyword', activeFilterLabelMap: enumToObject(SellerCartCheckStatus) },
    ])
  }, [filterOptions, overviewData])

  // Fetch entities data
  const { verticalEntities, fetchNextPage, isFetching, isLoading, isError } =
    useEcommerceExtendedTableQuery<EcommerceExtendedEntityForTable>({
      verticalIndices: currentZone?.ecommerceExtendedIndices,
      currentQueryKey: 'ecommerce-extended-entities-key',
      searchValue: searchValueDebounced,
      filters: activeFiltersDebounced,
      sorting,
    })

  const overviewSummaryData = useMemo(() => {
    if (!overviewData) return null
    const overviewDataCopy = { ...overviewData }
    delete overviewDataCopy.total
    return overviewDataCopy
  }, [overviewData])

  return (
    <div className={styles.container}>
      <nav id='navigationHeaderWrapper' className={styles.navigationHeaderWrapper}>
        <NavigationHeader />
      </nav>
      <header className='page-header withNavigationHeader'>
        <h1 className={cx('page-title', 'h1')}>
          <FormattedMessage {...messages.header} />
        </h1>
      </header>
      <main className={styles.main}>
        <div className={styles.contentWrapper}>
          <section className={styles.summary} id={'businessSummary'}>
            <EcommerceExtendedSummary summaryData={overviewSummaryData as IEcommerceExtendedSummary} />
          </section>
          <div className={cx(styles.content, { [styles.withFilters]: false })}>
            <div className={styles.gridContainer}>
              <TableActionsBars
                countMessages={messages.count}
                displayedCount={overviewData ? (verticalEntities?.length ?? null) : null}
                totalCount={overviewData && verticalEntities?.length ? overviewData.total : null}
                zoneEndpoint={currentZone?.ecommerceExtendedIndices}
                csvFileName={csvFileName}
                type={getUrlPageValues()[0]}
                withExport={true}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                mergedColumns={mergedColumns}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                allColumns={mergedColumns}
                filtersInitialState={filtersInitialState}
                filterOptions={filterOptions}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                filterAdditionalData={filterAdditionalData}
                emptyColumns={emptyColumns}
              />
              {isLoading ? (
                <GridLoader />
              ) : isError ? (
                <Alert severity='error' sx={{ fontFamily: 'Source Sans Pro', fontSize: '1rem', alignItems: 'center' }}>
                  Something went wrong
                </Alert>
              ) : (
                <section className={styles.grid}>
                  <EcommerceExtendedTable
                    isFetching={isFetching}
                    fetchNextPage={fetchNextPage}
                    totalFetched={verticalEntities?.length || 0}
                    totalDBRowCount={verticalEntities?.length ? (overviewData?.total ?? 0) : 0}
                    verticalEntities={verticalEntities}
                    sorting={sorting}
                    setSorting={setSorting}
                    setMergedColumns={setMergedColumns}
                    selectedColumns={selectedColumns}
                    allColumns={mergedColumns}
                  />
                </section>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default EcommerceExtendedTablePage
