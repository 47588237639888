import { useQuery } from '@tanstack/react-query'
import { fetchEmptyEcommerceExtendedTableValues } from 'services/verticalsTablesApi'

interface UseVerticalOverviewQueryProps {
  verticalIndices: string
  currentQueryKey: string
}

export const useEcommerceExtendedEmptyColumnsQuery = <T extends string[]>({
  verticalIndices,
  currentQueryKey,
}: UseVerticalOverviewQueryProps) =>
  useQuery<T | null>({
    queryKey: [currentQueryKey, verticalIndices],
    queryFn: () => (verticalIndices ? fetchEmptyEcommerceExtendedTableValues(verticalIndices) : null),
  })
