import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { normalizeUrlProtocol } from 'containers/VerticalPage/common/utils'

interface SocialBlockProps {
  logo: React.ReactNode
  text: string
  backgroundColor: string
  textColor: string
}

const SocialBlock = ({ logo, text, backgroundColor, textColor }: SocialBlockProps) => {
  return (
    <Link
      href={normalizeUrlProtocol(text)}
      target='_blank'
      rel='noopener noreferrer'
      sx={{
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'none',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: backgroundColor,
          padding: 2,
          borderRadius: 1,
          width: '350px',
          cursor: 'pointer',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {logo}
          <Typography
            variant='body1'
            color={textColor}
            sx={{
              fontFamily: 'Source Sans Pro',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
              textAlign: 'left',
            }}
          >
            {text.length > 35 ? `${text.slice(0, 32)}...` : text}
          </Typography>
        </Box>
      </Box>
    </Link>
  )
}

export default SocialBlock
