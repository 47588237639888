// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContactInfo__contactInfoContainer--xDpcD{display:flex;flex-direction:column;gap:16px}.ContactInfo__contactInfoContainer--xDpcD h2{font-size:18px;font-weight:600;line-height:32px;text-align:left;font-family:\"Dosis\";color:#1d294b;letter-spacing:.5px;align-self:flex-start}", "",{"version":3,"sources":["webpack://./app/containers/VerticalPage/ecommerce-extended/ContactInfo/ContactInfo.scss"],"names":[],"mappings":"AAAA,0CACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,6CACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CACA,mBAAA,CACA,aAAA,CACA,mBAAA,CACA,qBAAA","sourcesContent":[".contactInfoContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  h2 {\n    font-size: 18px;\n    font-weight: 600;\n    line-height: 32px;\n    text-align: left;\n    font-family: 'Dosis';\n    color: #1d294b;\n    letter-spacing: 0.5px;\n    align-self: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactInfoContainer": "ContactInfo__contactInfoContainer--xDpcD"
};
export default ___CSS_LOADER_EXPORT___;
