import { useContext, useMemo } from 'react'
import { VerticalContext } from 'store/verticals/Context'
import { ShopData } from 'containers/VerticalPage/ecommerce-extended/ProductsByShopTooltip/types'

export const useProductsByShops = (): ShopData[] | null => {
  const { verticalEntity } = useContext(VerticalContext)
  if (!verticalEntity?.revenueSources?.length) return null

  const productsByShops = useMemo(
    () =>
      verticalEntity.revenueSources.reduce((acc, revenueSource) => {
        if (!revenueSource?.profileName || !revenueSource?.overview?.cartCheck?.products) return acc
        acc.push({ shopName: revenueSource?.profileName, products: revenueSource.overview.cartCheck.products })
        return acc
      }, [] as ShopData[]),
    [verticalEntity],
  )

  return productsByShops
}
