import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/system/Box'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import TextTooltip from 'components/Tooltip'
import Typography from '@mui/material/Typography'
import styles from './styles'
import RegistryNumberColumn from 'components/ecommerceExtended/EcommerceExtendedTable/RegistryNumberColumn'

const bagIconUrl = require('./icons/bag.svg')
const statusColors = {
  active: '#34C759',
  inactive: '#FF3B30',
}

const renderTooltip = (textArray: string[]) => {
  if (textArray.length === 1) return textArray[0]
  if (textArray.length > 1) {
    return (
      <TextTooltip position='bottom' html={<>{textArray.join(' | ')}</>} theme='light'>
        <Stack direction='row' gap={1}>
          <Typography sx={styles.tooltipText}>{textArray[0]}</Typography>
          <Typography sx={styles.moreItemsLabel}>{` + ${textArray.length - 1} more`}</Typography>
        </Stack>
      </TextTooltip>
    )
  }

  return 'N/A'
}

const columns: GridColDef[] = [
  {
    flex: 3,
    field: 'businessNames',
    headerName: 'Business Names',
    renderCell: row => {
      return (
        <Stack direction='row' alignItems='center' gap={1}>
          <Box component='img' width={20} height={20} src={bagIconUrl} alt='Bag' />
          {renderTooltip(row.value)}
        </Stack>
      )
    },
  },
  { flex: 3, field: 'dba', headerName: 'DBA', maxWidth: 200 },
  { flex: 3, field: 'addresses', headerName: 'Addresses', renderCell: row => renderTooltip(row.value) },
  {
    flex: 3,
    field: 'sellerRegId',
    headerName: 'Seller Reg. id',
    renderCell: row => {
      return <RegistryNumberColumn registry={[{ ids: row.value }]} />
    },
  },
  {
    flex: 1.5,
    field: 'status',
    headerName: 'Status',
    renderCell: row => (
      <Stack direction='row' alignItems='center' gap={0}>
        {row.value ? (
          <>
            <FiberManualRecordIcon
              sx={{ color: statusColors[row.value?.toLowerCase()], circle: { r: 5 }, transform: 'translateX(-6px)' }}
            />
            {row.value}
          </>
        ) : (
          'N/A'
        )}
      </Stack>
    ),
  },
  { flex: 3, field: 'startDate', headerName: 'Start', maxWidth: 200 },
  { flex: 3, field: 'endDate', headerName: 'End', maxWidth: 200 },
]

export default columns
