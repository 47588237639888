import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CaretTypes } from 'containers/VerticalPage/common/VerticalCard/types'
import styles from './VerticalCard.module.scss'

interface CardToggleCaretProps {
  caretType: CaretTypes
  caretColor?: string
  handleClick: () => void
}

const CardToggleCaret = ({ caretType, handleClick, caretColor = 'white' }: CardToggleCaretProps) => {
  const rotateValue = caretType === CaretTypes.Collapse ? '180deg' : '0deg'
  return (
    <button className={styles.cardToggleCaret} onClick={handleClick} style={{ transform: `rotate(${rotateValue})` }}>
      <ExpandMoreIcon style={{ height: '1.25em', width: '1.25em' }} sx={{ color: caretColor }} />
    </button>
  )
}

export default CardToggleCaret
