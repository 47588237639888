export enum tabsIds {
  OVERVIEW,
  SELLER_REGISTRY,
  EMPLOYEES,
  SOLD_ITEMS,
  REVIEWS,
  CONTACT_INFO,
}

const tabsList = {
  OVERVIEW: { id: tabsIds.OVERVIEW, label: 'Overview' },
  SELLER_REGISTRY: { id: tabsIds.SELLER_REGISTRY, label: 'Seller Registry' },
  EMPLOYEES: { id: tabsIds.EMPLOYEES, label: 'Employees' },
  SOLD_ITEMS: { id: tabsIds.SOLD_ITEMS, label: 'Sold Items' },
  REVIEWS: { id: tabsIds.REVIEWS, label: 'Reviews' },
  CONTACT_INFO: { id: tabsIds.CONTACT_INFO, label: 'Contact Info' },
}

export const getTabsList = () => [
  tabsList.OVERVIEW,
  tabsList.SELLER_REGISTRY,
  tabsList.EMPLOYEES,
  tabsList.SOLD_ITEMS,
  tabsList.REVIEWS,
  tabsList.CONTACT_INFO,
]

export const overviewMapping = [
  { key: 'activityType', label: 'Activity Type' },
  { key: 'firstActivity', label: 'First Activity' },
  { key: 'documentedRevenue', label: 'Total Revenue' },
  { key: 'reportedRevenue', label: 'Reported Revenue' },
  { key: 'taxGap', label: 'Unreported Revenue' },
]
