import React, { ReactNode } from 'react'
import Stack from '@mui/material/Stack'
import { SxProps, useTheme } from '@mui/material/styles'
import GenericText from 'components/common/GenericText'
import Tooltip from 'components/Tooltip'
import Typography from '@mui/material/Typography'
import InfoIcon from '@mui/icons-material/Info'
import { TooltipText } from 'containers/VerticalPage/common/Overview/styles'
import { TooltipData } from 'containers/VerticalPage/common/types'
import { NOT_AVAILABLE } from 'utils/constants'

interface TitledDataProps {
  title: string
  data: string | ReactNode
  sx?: SxProps
  dataSx?: SxProps
  titleSx?: SxProps
  tooltipData?: TooltipData
}

const TitledData = ({ title, titleSx, data, dataSx, sx, tooltipData }: TitledDataProps) => {
  const theme = useTheme()
  return (
    <Stack component='li' spacing={1} sx={{ flexDirection: 'column', ...sx }}>
      <GenericText
        sx={{
          fontSize: 15,
          textTransform: 'capitalize',
          textAlign: 'center',
          color: theme.palette.textColor.lightGray,
          ...titleSx,
        }}
      >
        {title}
      </GenericText>
      <Stack direction='row' gap='4px' alignItems='center'>
        <Stack
          sx={{
            textTransform: 'capitalize',
            fontSize: 22,
            fontFamily: 'Dosis',
            fontWeight: 600,
            textAlign: 'center',
            ...dataSx,
          }}
        >
          {data}
        </Stack>
        {tooltipData?.text && tooltipData.text !== 'no text' ? (
          <Tooltip
            position='bottom'
            html={<Typography sx={TooltipText}>{tooltipData?.text || NOT_AVAILABLE}</Typography>}
            theme='light'
          >
            <InfoIcon
              htmlColor='rgba(126, 139, 166, 1)'
              sx={{ width: '16px', height: '16px', display: 'flex', ...tooltipData?.iconSx }}
            />
          </Tooltip>
        ) : tooltipData?.text === 'no text' ? (
          <InfoIcon
            htmlColor='rgba(126, 139, 166, 1)'
            sx={{ width: '16px', height: '16px', display: 'flex', ...tooltipData?.iconSx }}
          />
        ) : null}
      </Stack>
    </Stack>
  )
}

export default TitledData
