import React, { FC } from 'react'
import { PLATFORMS } from 'store/verticals/types'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import GenericHeading from 'components/common/GenericHeading'
import { originalSourceToImageMap } from 'components/BusinessSource/specs'
import GenericText from 'components/common/GenericText'
import GenericHeadingLink from 'components/common/GenericHeadingLink'
import styles from './VerticalBusinessActivity.scss'

const avatarIcon = require('containers/VerticalPage/nft/assets/avatar.png')

interface VerticalBARevenueSourceProps {
  user: string
  userPhoto: string
  activityType: string
  activityUrl: string
  platform: PLATFORMS
  activityDate: string | null
  hideUserImage?: boolean
  withSourceLink?: boolean
}

const VerticalBARevenueSource: FC<VerticalBARevenueSourceProps> = ({
  user,
  userPhoto,
  activityType,
  activityUrl,
  platform,
  activityDate,
  hideUserImage,
  withSourceLink = true,
}) => {
  return (
    <Stack
      sx={{
        pt: { md: '8px', lg: '8px', xl: '14px', xxl: '28px', xxxl: '40px' },
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        sx={{
          mb: { md: 0.4, lg: 0.4, xl: 2, xxl: 2.5, xxxl: 3 },
        }}
      >
        {!hideUserImage && (
          <Box mr={{ md: 0.3, lg: 0.4, xxl: 1, xxxl: 1.2 }}>
            <img
              src={userPhoto || avatarIcon}
              alt='User'
              width={24}
              height={24}
              style={{ borderRadius: '50%' }}
              className={styles.revenueSourceIcon}
            />
          </Box>
        )}
        <GenericHeading
          sx={{
            fontSize: { md: 14, lg: 14, xl: 16, xxl: 24, xxxl: 42 },
            fontWeight: '700',
            maxHeight: { md: 24, lg: 24, xxl: 'none', xxxl: 'none' },
            overflow: 'hidden',
          }}
        >
          {user}
        </GenericHeading>
      </Stack>

      <Stack direction='row' alignItems='center' spacing={1}>
        <img
          src={originalSourceToImageMap[platform?.toLowerCase()]}
          alt='Platform'
          width={16}
          height={16}
          className={styles.activityTypeIcon}
        />
        {withSourceLink && (
          <GenericHeadingLink
            href={activityUrl}
            headingSx={{
              color: '#1A56E5',
              textDecoration: 'underline',
              textTransform: 'capitalize',
              fontSize: { md: 14, lg: 14, xl: 16, xxl: 22, xxxl: 34 },
              fontWeight: '700',
              fontFamily: 'Source Sans Pro',
            }}
          >
            {activityType}
          </GenericHeadingLink>
        )}
      </Stack>
      {activityDate && (
        <GenericText
          sx={{
            fontSize: { md: 12, lg: 12, xl: 14, xxl: 18, xxxl: 24 },
            color: '#7E7E7E',
          }}
        >
          {activityDate}
        </GenericText>
      )}
    </Stack>
  )
}

export default VerticalBARevenueSource
