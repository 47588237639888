import React, { useState } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Tooltip from 'components/Tooltip'
import ProductsByShops from 'containers/VerticalPage/ecommerce-extended/ProductsByShopTooltip/ProductsByShop'
import { useProductsByShops } from 'containers/VerticalPage/ecommerce-extended/ProductsByShopTooltip/useProductsByShop'
import GenericText from 'components/common/GenericText'

interface ProductsTooltipProps {}

const ProductsTooltip = ({}: ProductsTooltipProps) => {
  const productsByShops = useProductsByShops()
  const [open, setOpen] = useState(false)

  return (
    <Box onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <Tooltip
        html={<ProductsByShops shopsData={productsByShops || []} />}
        theme='light'
        placement='top-end'
        customTooltipStyles={{ maxWidth: 'none', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}
        open={open}
      >
        <Stack
          direction='row'
          sx={{ fontFamily: 'Source Sans Pro', fontSize: 16, fontWeight: 400, color: '#2257C5', alignItems: 'center' }}
        >
          <GenericText textAlign='center'>View Products</GenericText>
          <ExpandLessIcon
            fontSize='medium'
            sx={{ color: '#2257C5', transform: 'rotate(90deg)', display: 'block' }}
            onMouseEnter={() => setOpen(true)}
          />
        </Stack>
      </Tooltip>
    </Box>
  )
}

export default ProductsTooltip
