import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { fetchCompanyOverviewEmployeesData } from 'services/verticalsApi'
import { Employee } from './types'
import { Filter } from 'components/FilterDrawer/FilterDrawer.config'

interface useEmployeesQueryProps {
  caseId: string
  zoneActivityIndices: string | null
  pageSize: number
  searchValue: string
  filters: Filter[]
  sort?: string | qs.ParsedQs | string[] | qs.ParsedQs[]
  page?: number
}

export const useEmployeesQuery = ({
  caseId,
  zoneActivityIndices,
  pageSize,
  searchValue,
  filters,
  sort,
  page,
}: useEmployeesQueryProps) => {
  const {
    data: verticalActivities,
    fetchNextPage,
    isFetching,
    isLoading,
  } = useInfiniteQuery<any>({
    queryKey: [caseId, zoneActivityIndices, pageSize, searchValue, filters, page, sort],
    queryFn: async ({ pageParam = 0, signal }) => {
      if (!zoneActivityIndices) return null
      const fromIndex = (page || pageParam) * pageSize
      return await fetchCompanyOverviewEmployeesData(
        caseId,
        fromIndex,
        zoneActivityIndices,
        searchValue,
        filters,
        pageSize,
        signal,
      )
    },
    getNextPageParam: (_lastGroup, groups) => groups.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { flatEntities, total }: { flatEntities: any, total: number } = useMemo(() => {
    if (!verticalActivities?.pages) {
      return { flatEntities: null, total: 0 }
    }

    const flatEntities =
      verticalActivities.pages.flatMap((page: { total: number; items: Employee[] }) => page.items) || null

    const total = verticalActivities.pages[0]?.total || 0

    return {
      flatEntities: flatEntities?.length > 0 ? flatEntities : null,
      total,
    }
  }, [verticalActivities])

  return {
    verticalDocumentActivity: flatEntities,
    fetchNextPage,
    isFetching,
    isLoading,
    totalFetched: flatEntities?.length || 0,
    total,
  }
}
