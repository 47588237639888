import { IRegistryIds } from 'store/verticals/types'

export const dotColors = {
  green: '#34C759',
  blue: '#5AC8FA',
  red: '#FF3B30',
  orange: '#FF9500',
  yellow: '#FFCC00',
}

export const riskLabels = ['Low', 'Moderate', 'High', 'Critical']
export const magnitudeLabels = ['Micro', 'Small', 'Medium', 'Large', 'Major']

export const getRiskConfig = (score: number) => {
  let color, label
  if (score <= 0.25) {
    color = dotColors.green
    label = riskLabels[0]
  } else if (score <= 0.5) {
    color = dotColors.yellow
    label = riskLabels[1]
  } else if (score <= 0.75) {
    color = dotColors.orange
    label = riskLabels[2]
  } else {
    color = dotColors.red
    label = riskLabels[3]
  }

  return {
    label,
    color,
  }
}
export const getMagnitudeConfig = (score: number) => {
  let color, label
  if (score <= 0.2) {
    label = magnitudeLabels[0]
    color = dotColors.blue
  } else if (score <= 0.4) {
    label = magnitudeLabels[1]
    color = dotColors.green
  } else if (score <= 0.6) {
    label = magnitudeLabels[2]
    color = dotColors.yellow
  } else if (score <= 0.8) {
    label = magnitudeLabels[3]
    color = dotColors.orange
  } else {
    label = magnitudeLabels[4]
    color = dotColors.red
  }

  return {
    label,
    color,
  }
}

export const getRegistryData = (
  data: { ids: IRegistryIds[] }[],
): { key: string; label: string; values: string[] }[] => {
  const combinedKeys = {}

  data.forEach(item => {
    item.ids.forEach(id => {
      if (!combinedKeys[id.key]) {
        combinedKeys[id.key] = []
      }

      if (!combinedKeys[id.key].includes(id.value)) {
        combinedKeys[id.key].push(id.value)
      }
    })
  })

  return Object.keys(combinedKeys).map(key => ({
    key: key,
    label: key
      .toLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
    values: combinedKeys[key],
  }))
}
