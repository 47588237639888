import React from 'react'
import TitledData from 'components/common/TitledData'
import Stack from '@mui/material/Stack'
import { SxProps, useTheme } from '@mui/material/styles'
import { RevenueSourceOverview } from 'store/verticals/types'
import { splitCamelCase } from 'utils/splitCamelCase'
import { useProcessRevenueSources } from 'containers/VerticalPage/common/RevenueSourcesOverview/useProcessRevenueSources'

interface RevenueSourcesOverviewProps {
  revenueSources: RevenueSourceOverview
  includeFields?: string[]
  titledDataSx?: SxProps
  customOrder?: string[]
}

const RevenueSourcesOverview = ({
  revenueSources,
  includeFields,
  titledDataSx,
  customOrder,
}: RevenueSourcesOverviewProps) => {
  const theme = useTheme()
  const processedRevenueSources = useProcessRevenueSources({ revenueSources, includeFields, customOrder })

  return (
    <Stack
      component='ul'
      direction='row'
      sx={{
        justifyContent: 'space-around',
        alignItems: 'flex-end',
        mt: { md: '25px', lg: '35px', xl: '50px', xxl: '72px', xxxl: '120px' },
      }}
    >
      {processedRevenueSources.map(({ processedKey, key, value, tooltip }) => (
        <TitledData
          key={key}
          title={processedKey || splitCamelCase(key)}
          data={value}
          tooltipData={tooltip}
          sx={{ flexDirection: 'column-reverse', justifyContent: 'center', alignItems: 'center', ...titledDataSx }}
          titleSx={{
            color: theme.palette.textColor.lightGray,
            fontSize: { md: '10px', lg: '10px', xl: '12px', xxl: '16px', xxxl: '24px' },
            fontFamily: 'Source Sans Pro',
          }}
          dataSx={{
            fontSize: { md: '13px', lg: '14px', xl: '16px', xxl: '22px', xxxl: '34px' },
            letterSpacing: '0.431px',
            color: '#282828',
          }}
        />
      ))}
    </Stack>
  )
}

export default RevenueSourcesOverview
