import React, { ReactNode, FC } from 'react'
import Stack from '@mui/material/Stack'
import { SxProps } from '@mui/material'

interface ITabScrolledContent {
  children?: ReactNode
  sxProps?: SxProps
}

const TabScrolledContent: FC<ITabScrolledContent> = ({ children, sxProps = {} }) => (
  <Stack sx={{ p: '16px', ...sxProps }}>{children}</Stack>
)

export default TabScrolledContent
