import { useState, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { PeriodProps } from 'components/common/DatePicker/utils'
import { Period } from 'utils/types'
import { VerticalContext } from 'store/verticals/Context'
import { CurrentZone } from 'utils/types'
import { selectCurrentZone } from 'store/global'
import { SellerCartCheckStatus } from 'containers/VerticalPage/common/types'

const usePeriodSetter = (defaultDate: Period) => {
  const [period, setPeriod] = useState({ start: defaultDate.start, end: defaultDate.end })

  const handlePeriodChange = (newPeriod: PeriodProps) => {
    const { startDate, endDate } = newPeriod
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD')
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD')
    setPeriod({ start: formattedStartDate, end: formattedEndDate })
  }

  return {
    period,
    handlePeriodChange,
  }
}

const useChartDataFetching = () => {
  const { getVerticalPieChartsData, getVerticalBarChartsData } = useContext(VerticalContext)
  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  const [isLoadingPies, setIsLoadingPies] = useState(false)
  const [isLoadingBar, setIsLoadingBar] = useState(false)

  const fetchPieChartsData = async (currentEntityId: string, currentPeriod: Period) => {
    if (currentZone?.influencersActivityIndices) {
      setIsLoadingPies(true)
      await getVerticalPieChartsData(currentEntityId, currentPeriod, currentZone.influencersActivityIndices)
      setIsLoadingPies(false)
    }
  }

  const fetchBarChartData = async (currentEntityId: string, currentPeriod: Period) => {
    if (currentZone?.influencersActivityIndices) {
      setIsLoadingBar(true)
      await getVerticalBarChartsData(currentEntityId, currentPeriod, currentZone.influencersActivityIndices)
      setIsLoadingBar(false)
    }
  }

  return {
    isLoadingPies,
    isLoadingBar,
    fetchPieChartsData,
    fetchBarChartData,
  }
}

const useCopyToClipboard = () => {
  const [isLinkCopied, setIsLinkCopied] = useState(false)

  useEffect(() => {
    if (isLinkCopied) {
      const timer = setTimeout(() => {
        setIsLinkCopied(false)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [isLinkCopied])

  const handleCopy = (text: string) => {
    // clipboard.writeText is not supported on http protocol
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).then(
        () => {
          setIsLinkCopied(true)
        },
        err => {
          console.error('Could not copy text: ', err)
        },
      )
    } else {
      // Fallback for unsupported browsers
      const textArea = document.createElement('textarea')
      textArea.value = text
      textArea.style.position = 'fixed' // Prevent scrolling to the bottom
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        document.execCommand('copy')
        setIsLinkCopied(true)
      } catch (err) {
        console.error('Fallback: Could not copy text: ', err)
      }
      document.body.removeChild(textArea)
    }
  }

  return { handleCopy, isLinkCopied }
}

const useSellerCartCheckMessage = (sellerCartCheckStatus?: string) => {
  const [cartCheckMessage, setCartCheckMessage] = useState('N/A')

  useEffect(() => {
    if (!sellerCartCheckStatus) return
    const message = SellerCartCheckStatus?.[sellerCartCheckStatus]
    setCartCheckMessage(message)
  }, [sellerCartCheckStatus])

  return cartCheckMessage
}

export { usePeriodSetter, useChartDataFetching, useCopyToClipboard, useSellerCartCheckMessage }
