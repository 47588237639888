import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import LeafletMap from 'components/LeafletMap/LeafletMap'
import ScoreCard from 'components/ScoreCard/ScoreCard'
import React from 'react'
import TabScrolledContent from '../TabScrolledContent/TabScrolledContent'
import styles from './Overview.scss'
import { Location } from 'components/LeafletMap/LeafletMap'
import { getAbbreviatedNumberString } from 'components/Income/Income'
import CircleIcon from '@mui/icons-material/Circle'
import ProductsTooltip from 'containers/VerticalPage/ecommerce-extended/ProductsByShopTooltip/ProductsTooltip'
import { useSellerCartCheckMessage } from 'containers/VerticalPage/common/hooks'
import { getScoreCardValue } from 'containers/VerticalPage/ecommerce-extended/Overview/utils'
import { getMagnitudeConfig, getRiskConfig } from 'components/ecommerceExtended/EcommerceExtendedTable/util'
import { EcomExtendedSellerRegStatusEnum } from 'containers/EcommerceExtendedTablePage/utils'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import GenericText from 'components/common/GenericText'

const employeeIconUrl = require('../ActivityOverviewCard/icons/employee.svg')
const addressIconUrl = require('./icons/address.svg')

interface OverviewProps {
  magnitude: {
    score: number
    [key: string]: any
  }
  risk: {
    score: number
    [key: string]: any
  }
  locations: Location[] | []
  cartCheckStatus?: string
  setActiveTab: (value: number) => void
  setEmployeeIds: (value: number[]) => void
}

const Overview = ({ magnitude, risk, locations, cartCheckStatus, setEmployeeIds, setActiveTab }: OverviewProps) => {
  const riskConfig = getRiskConfig(risk?.score) || null
  const magnitudeConfig = getMagnitudeConfig(magnitude.score) || null

  const magnitudeParameters = [
    {
      key: 'numberOfFollowers',
      label: 'Social Media Followers',
      value: magnitude?.numberOfFollowers?.toLocaleString(),
    },
    { key: 'numberOfShops', label: 'Number of Shops', value: magnitude?.numberOfShops?.toLocaleString() },
    { key: 'rank', label: 'Website Ranking', value: magnitude?.rank?.toLocaleString() },
    {
      key: 'totalTraffic3Months',
      label: 'Avg. Monthly Web Traffic',
      value: getAbbreviatedNumberString(magnitude.totalTraffic3Months / 3),
    },
    { key: 'yearsOfOperation', label: 'Years in Business' },
  ]

  const sellerCartCheck = useSellerCartCheckMessage(cartCheckStatus)

  const riskParameters = [
    {
      key: 'taxCheckedProducts',
      label: sellerCartCheck,
      value: <ProductsTooltip />,
    },
    {
      key: 'physicalNexus.address',
      label: 'Physical address in Jurisdiction',
      value:
        (risk.physicalNexus?.address && (
          <img src={addressIconUrl} alt={'Physical nexus address'} className={styles.verticalIcon} />
        )) ||
        null,
    },
    {
      key: 'physicalNexus.employees',
      label: 'Employees in Jurisdiction',
      value:
        (risk.physicalNexus?.employees && (
          <img src={employeeIconUrl} alt={'Physical nexus employees'} className={styles.verticalIcon} />
        )) ||
        null,
    },
    {
      key: null,
      label: 'Magnitude Score',
      value: (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', marginLeft: 'auto' }}>
          <CircleIcon fontSize='small' style={{ fontSize: '12px', color: magnitudeConfig?.color }} />
          <span>{magnitudeConfig?.label}</span>
        </Box>
      ),
    },
    {
      key: 'registrationStatus',
      label: 'Registry Status',
      value: (() => {
        switch (risk.registrationStatus) {
          case 'Registered':
            return (
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: '8px', marginLeft: 'auto' }}>
                <CheckIcon sx={{ color: '#34C759', fontSize: '16px' }} />
                <GenericText>{EcomExtendedSellerRegStatusEnum.Registered}</GenericText>
              </Stack>
            )
          case 'Unregistered':
            return (
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: '8px', marginLeft: 'auto' }}>
                <CloseIcon sx={{ color: '#FF3B30', fontSize: '16px' }} />
                <GenericText>{EcomExtendedSellerRegStatusEnum.Unregistered}</GenericText>
              </Stack>
            )
          case 'LateRegistration':
            return (
              <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: '8px', marginLeft: 'auto' }}>
                <GenericText>{EcomExtendedSellerRegStatusEnum.LateRegistration}</GenericText>
              </Stack>
            )
          case 'ExpiredRegistration':
            return risk.registrationStatus
          default:
            return null
        }
      })(),
    },
  ]

  return (
    <TabScrolledContent sxProps={{ height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: '24px',
          height: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': { width: '32px' },
          '&::-webkit-scrollbar-thumb': {
            borderWidth: '12px',
            borderStyle: 'solid',
            borderColor: 'rgba(0, 0, 0, 0)',
            backgroundClip: 'padding-box',
            borderRadius: '20px',
            backgroundColor: '#7E8BA6',
          },
          '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#677187' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: '24px',
            height: '100%',
          }}
        >
          <ScoreCard
            title='Magnitude'
            parameters={magnitudeParameters.map(({ key, label, value }) => ({
              parameter: label,
              html: (value && <>{value}</>) || <>{magnitude?.[key]}</> || null,
            }))}
            value={getScoreCardValue(magnitude?.score)}
            scoreColor={magnitudeConfig?.color}
            escalationLabel={magnitudeConfig?.label}
          />
          <ScoreCard
            title='Risk'
            parameters={riskParameters.map(({ label, value }) => ({
              parameter: label,
              html: (value && <>{value}</>) || null,
            }))}
            value={getScoreCardValue(risk?.score)}
            escalationLabel={riskConfig?.label}
            scoreColor={riskConfig?.color}
            withEscalationLabel={true}
          />
        </Box>
        <Box sx={{ width: '100%', height: '100%' }}>
          <Box className={styles.locationsContainer}>
            <h3>Locations</h3>
            <Box className={styles.legendContainer}>
              <div className={styles.legendRow}>
                <CircleIcon fontSize='small' style={{ fontSize: '10px', color: '#2257C5' }} />
                <span>Business Addresses</span>
              </div>
              <div className={styles.legendRow}>
                <CircleIcon fontSize='small' style={{ fontSize: '10px', color: '#FF5837' }} />
                <span>Employees Location</span>
              </div>
            </Box>
          </Box>
          <LeafletMap locations={locations} setActiveTab={setActiveTab} setEmployeeIds={setEmployeeIds} />
        </Box>
      </Box>
    </TabScrolledContent>
  )
}

export default Overview
