import React, { useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useMap } from 'react-leaflet'
import L from 'leaflet'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import 'leaflet/dist/leaflet.css'

const AddZoomControl = () => {
  const map = useMap()
  map.setMinZoom(1)
  map.setMaxZoom(4)

  useEffect(() => {
    const zoomInButton = L.DomUtil.create('button', 'custom-zoom-in')
    zoomInButton.innerHTML = ReactDOMServer.renderToString(<AddIcon fontSize='small' style={{ color: '#2257C5' }} />)
    zoomInButton.title = 'Zoom in'
    Object.assign(zoomInButton.style, {
      border: 'none',
      color: 'white',
      background: 'none',
      cursor: 'pointer',
      borderBottom: '1px solid #DADBDF',
      padding: '2px 2.2px',
    })

    const zoomOutButton = L.DomUtil.create('button', 'custom-zoom-out')
    zoomOutButton.innerHTML = ReactDOMServer.renderToString(
      <RemoveIcon fontSize='small' style={{ color: '#2257C5' }} />,
    )
    zoomOutButton.title = 'Zoom out'
    Object.assign(zoomOutButton.style, {
      border: 'none',
      color: 'white',
      background: 'none',
      cursor: 'pointer',
      padding: '2px 2.2px',
    })

    const zoomContainer = L.DomUtil.create('div', '')
    Object.assign(zoomContainer.style, {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      border: '1px solid #DADBDF',
      borderRadius: '6px',
      margin: '0 24px 24px 0',
    })
    zoomContainer.appendChild(zoomInButton)
    zoomContainer.appendChild(zoomOutButton)

    const zoomControl = new L.Control({ position: 'bottomright' })
    zoomControl.onAdd = () => zoomContainer
    zoomControl.addTo(map)

    L.DomEvent.on(zoomInButton, 'click', () => map.zoomIn())
    L.DomEvent.on(zoomOutButton, 'click', () => map.zoomOut())

    return () => {
      map.removeControl(zoomControl)
    }
  }, [map])

  return null
}

export default AddZoomControl
