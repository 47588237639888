import { SxProps } from '@mui/material'

const container: SxProps = {
  backgroundColor: '#fff',
  border: 'solid 1px #DADBDF',
  padding: '16px 24px',
  borderRadius: '8px',
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
  textAlign: 'left',
  height: '100%',
}

const styles = { container }

export default styles
