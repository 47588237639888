import { VerticalsAction, VERTICAL_ACTIONS } from 'store/verticals/actions'
import { VerticalsState } from 'store/verticals/types'

export const VerticalsReducer = (state: VerticalsState, action: VerticalsAction): VerticalsState => {
  switch (action.type) {
    case VERTICAL_ACTIONS.VerticalEntity:
      return {
        ...state,
        verticalEntity: action.verticalEntity,
      }
    case VERTICAL_ACTIONS.VerticalDocumentActivityStats:
      return {
        ...state,
        verticalDocumentActivityStats: action.verticalDocumentActivityStats,
      }
    case VERTICAL_ACTIONS.VerticalRevenueBySources:
      return {
        ...state,
        verticalRevenueBySources: action.verticalRevenueBySources,
      }
    case VERTICAL_ACTIONS.VerticalEstimatedRevenue:
      return {
        ...state,
        verticalEstimatedRevenue: action.verticalEstimatedRevenue,
      }
    case VERTICAL_ACTIONS.VerticalReportedRevenue:
      return {
        ...state,
        verticalReportedRevenue: action.verticalReportedRevenue,
      }
    case VERTICAL_ACTIONS.GraphData:
      return {
        ...state,
        graphData: action.graphData,
      }
    case VERTICAL_ACTIONS.SetRevenuesCarousel:
      return {
        ...state,
        isCollapsedRevenuesCarousel: !state.isCollapsedRevenuesCarousel,
      }
    default:
      return state
  }
}
