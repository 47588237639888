import { SxProps } from '@mui/material'

const container: SxProps = {
  overflow: 'hidden',
  height: '100%',
}

const tabWrapper: SxProps = {
  overflow: 'auto',
}

const styles = {
  container,
  tabWrapper,
}

export default styles
