import { VerticalEntity } from 'store/verticals/types'
import { BaseTotalsForTable, PieChartData } from 'utils/types'

export interface EcommerceTotals extends BaseTotalsForTable {
  totalItemsSold: number
  totalReviews: number
  numberOfEmployees?: number
}

export interface EcommerceExtendedEntityForTable extends VerticalEntity {
  totals: EcommerceTotals
}

export interface IEcommerceExtendedSummary {
  sellerRegistryStatus: SummarySellerRegistryStatus
  taxCollectionStatus: SummaryTaxCollectionStatus
  riskScoreDistribution: SummaryRiskScoreDistribution
}

export enum SummaryTitles {
  sellerRegistryStatus = 'Seller Registry Status',
  taxCollectionStatus = 'OVERALL Tax Collection UPON CHECKOUT',
  riskScoreDistribution = 'Risk Score Distribution',
}

export interface SummarySellerRegistryStatus {
  title: SummaryTitles.sellerRegistryStatus
  pieChartData: PieChartData[]
  total: number
  moreResultsCount: number
}

export interface SummaryTaxCollectionStatus {
  title: SummaryTitles.taxCollectionStatus
  pieChartData: PieChartData[]
  total: number
  moreResultsCount: number
}

export interface SummaryRiskScoreDistribution {
  title: SummaryTitles.riskScoreDistribution
  pieChartData: PieChartData[]
  total: number
  moreResultsCount: number
}
