import React, { FC } from 'react'
import GenericLink from './GenericLink'
import GenericHeading from './GenericHeading'
import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded'

interface GenericHeadingLinkProps {
  href: string
  linkSx?: SxProps
  headingSx?: SxProps<Theme>
  linkArrowIcon?: any
  children: React.ReactNode
}

const GenericHeadingLink: FC<GenericHeadingLinkProps> = ({ children, href, linkSx, headingSx }) => {
  return (
    <GenericLink
      href={href}
      sx={{
        position: 'relative',
        width: 'fit-content',
        maxWidth: { md: '280px', lg: '280px', xl: '280px', xxl: '400px', xxxl: '560px' },
        textDecoration: 'none',
        color: 'secondary.main',
        ...linkSx,
      }}
    >
      <GenericHeading
        sx={{
          position: 'relative',
          ...headingSx,
        }}
      >
        <ArrowOutwardRoundedIcon
          sx={{
            position: 'absolute',
            top: '2px',
            right: '-12px',
            fontSize: { md: '0.7rem', xxl: '0.9rem', xxxl: '1.2rem' },
            fontWeight: 'bold',
          }}
        />
        {children}
      </GenericHeading>
    </GenericLink>
  )
}

export default GenericHeadingLink
