import React, { ReactNode, useState } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import GaugeComponent from 'react-gauge-component'
import styles from './ScoreCard.styles.scss'

interface Parameter {
  parameter: string
  html: React.ReactNode
}

interface ScoreCardProps {
  title: 'Magnitude' | 'Risk'
  parameters: Parameter[]
  value: number
  escalationLabel?: string
  scoreColor?: string
  children?: ReactNode
  withEscalationLabel?: boolean
}

const ScoreCard = ({
  title,
  parameters,
  value,
  escalationLabel,
  scoreColor,
  children,
  withEscalationLabel,
}: ScoreCardProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleParameters = () => {
    setIsOpen(!isOpen)
  }

  const subArcs = [
    { limit: value, color: scoreColor },
    { limit: 100, color: '#DADBDF' },
  ]

  return (
    <Box className={styles.box}>
      <h3>{title}</h3>
      <Box sx={{ position: 'relative', width: 'fit-content', margin: '0 auto', height: '155px' }}>
        <GaugeComponent
          type='semicircle'
          value={value}
          minValue={0}
          maxValue={100}
          arc={{
            subArcs,
            width: 0.1,
          }}
          pointer={{
            hide: true,
          }}
          labels={{
            valueLabel: {
              hide: true,
            },
            tickLabels: {
              hideMinMax: true,
            },
          }}
        />
        <div
          style={{
            position: 'absolute',
            bottom: '14px',
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
          }}
        >
          {withEscalationLabel && (
            <div style={{ fontSize: '16px', color: '#7E8BA6', fontWeight: '400' }}>{escalationLabel}</div>
          )}
          <div style={{ fontSize: '2.5em', color: '#1C2842', fontWeight: '600', textTransform: 'uppercase' }}>
            {withEscalationLabel ? value?.toFixed(1) : escalationLabel}
          </div>
        </div>
      </Box>
      <Stack width='100%' sx={{ overflow: 'auto' }}>
        <h4 onClick={toggleParameters} className={styles.parametersTitle}>
          {title} Parameters {isOpen ? <ExpandLessIcon fontSize='large' /> : <ExpandMoreIcon fontSize='large' />}
        </h4>
        {isOpen && (
          <>
            {children || null}
            <ul className={styles.parameters}>
              {parameters.map(
                (param, index) =>
                  param.html && (
                    <li key={index}>
                      {param.parameter}
                      <div
                        style={{ minWidth: '75px', alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}
                      >
                        {param.html}
                      </div>
                    </li>
                  ),
              )}
            </ul>
          </>
        )}
      </Stack>
    </Box>
  )
}

export default ScoreCard
