const HeaderTitle = {
  p: 0,
  color: 'white',
}

const TinLabel = {
  fontWeight: 'bold',
  p: 0,
  fontSize: { md: '13px', lg: '14px', xl: '16px', xxl: '20px', xxxl: '30px' },
}

const TinValue = {
  fontSize: { md: '13px', lg: '14px', xl: '16px', xxl: '20px', xxxl: '30px' },
}

const CardBody = {
  p: { md: '10px', lg: '12px', xl: '12px 16px', xxl: '18px 26px 26px', xxxl: '36px 32px 0px' },
  '&:last-child': {
    paddingBottom: { md: '10px', lg: '12px', xl: '12px', xxl: '26px', xxxl: '20px' },
  },
}

const Avatar = {
  width: { md: 40, lg: 60, xl: 70, xxl: 110, xxxl: 160 },
  height: { md: 40, lg: 60, xl: 70, xxl: 110, xxxl: 160 },
}

const CardContent = {
  marginLeft: { md: '8px', lg: '12px', xl: '18px', xxl: '22px', xxxl: '24px' },
}

const EntityName = {
  textTransform: 'uppercase',
  color: '#1C2842',
  fontSize: { md: '14px', lg: '16px', xl: '20px', xxl: '28px', xxxl: '40px' },
  lineHeight: { md: '18px', lg: '20px', xl: '22px', xxl: 'auto', xxxl: 'auto' },
  fontWeight: 600,
}

const ProfilUrls = {
  mt: { md: '7px', lg: '8x', xl: '10px', xxl: '12px', xxxl: '24px' },
}

const styles = {
  HeaderTitle,
  TinLabel,
  TinValue,
  CardBody,
  Avatar,
  CardContent,
  EntityName,
  ProfilUrls,
}

export default styles
