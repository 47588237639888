import React, { FC, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Stack from '@mui/material/Stack'
import { tabsIds, getTabsList, overviewMapping } from './utils'
import Tabs from 'components/common/Tabs'
import GraphContainer from 'containers/VerticalPage/common/GraphContainer/GraphContainer'
import type { CurrentZone } from 'utils/types'
import { selectCurrentZone } from 'store/global'
import SellerRegistry from '../SellerRegistry/SellerRegistry'
import Overview from '../Overview/Overview'
import Employees from '../Employees/Employees'
import ContactInfo from '../ContactInfo/ContactInfo'
import { VerticalContext } from 'store/verticals/Context'
import { useWindowSize } from 'hooks/useWindowSize'
import sxStyles from './styles'
import styles from 'containers/VerticalPage/common/styles.scss'

const tabsList = getTabsList()

const TABLE_HEIGHT_PADDING = 40

const Details: FC = () => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  const [activeTab, setActiveTab] = useState<number>(tabsIds.OVERVIEW)
  const [tableHeight, setTableHeight] = useState<number>(0)

  const [employeeIds, setEmployeeIds] = useState<number[]>([])

  const { verticalEntity, isCollapsedRevenuesCarousel } = useContext(VerticalContext)
  const { height } = useWindowSize()

  const handleTabClick = (event: React.SyntheticEvent<Element, Event>, value: number) => {
    setActiveTab(value)
  }

  const tabContainerRef = useRef<HTMLInputElement>(null)

  useLayoutEffect(() => {
    const calculateHeight = () => {
      if (tabContainerRef.current) {
        const height = tabContainerRef.current.getBoundingClientRect().height - TABLE_HEIGHT_PADDING
        setTableHeight(height)
      }
    }

    calculateHeight()

    const timeoutId = setTimeout(calculateHeight, 50)

    return () => clearTimeout(timeoutId)
  }, [activeTab, isCollapsedRevenuesCarousel, height])

  const contentByTabMap: { [key: number]: JSX.Element } = useMemo(
    () => ({
      [tabsIds.OVERVIEW]: (
        <Overview
          magnitude={verticalEntity?.overviewDetails?.magnitude || { score: 0 }}
          risk={verticalEntity?.overviewDetails?.risk || { score: 0 }}
          locations={verticalEntity?.overviewDetails?.locations || []}
          cartCheckStatus={verticalEntity?.overviewDetails?.cartCheckStatus || ''}
          setActiveTab={setActiveTab}
          setEmployeeIds={setEmployeeIds}
        />
      ),
      [tabsIds.SELLER_REGISTRY]: <SellerRegistry />,
      [tabsIds.EMPLOYEES]: (
        <Employees tableHeight={tableHeight} employeeIds={employeeIds} setEmployeeIds={setEmployeeIds} />
      ),
      [tabsIds.CONTACT_INFO]: <ContactInfo contactInfo={verticalEntity?.contactInfo} />,
    }),
    [verticalEntity, tableHeight, employeeIds],
  )

  return (
    <Stack sx={sxStyles.container}>
      <div className={styles.tabsWrapper}>
        <Tabs
          tabs={tabsList}
          value={activeTab}
          disabledIds={[tabsIds.SOLD_ITEMS, tabsIds.REVIEWS]}
          tabsClasses={{
            root: styles.root,
            flexContainer: styles.flexContainer,
          }}
          tabClasses={{
            root: styles.tabRoot,
            disabled: styles.tabDisabled,
          }}
          tabsSx={{
            p: '0 16px 0 16px',
          }}
          onChange={handleTabClick}
        />
        {currentZone?.ecommerceExtendedGraphIndices && (
          <GraphContainer zone={currentZone.ecommerceExtendedGraphIndices} overviewMapping={overviewMapping} />
        )}
      </div>
      <Stack
        sx={{
          ...(tabsIds.CONTACT_INFO === activeTab ? { overflow: 'hidden' } : sxStyles.tabWrapper),
          ...(tabsIds.OVERVIEW === activeTab || tabsIds.CONTACT_INFO === activeTab || tabsIds.EMPLOYEES === activeTab
            ? { height: '100%' }
            : {}),
        }}
        ref={tabContainerRef}
      >
        {contentByTabMap[activeTab]}
      </Stack>
    </Stack>
  )
}

export default Details
