import { SxProps } from '@mui/material'

const tooltipContainer: SxProps = {
  textAlign: 'left',
  maxHeight: '300px',
  overflow: 'auto',
}

const rowContainer: SxProps = {
  marginBottom: '8px',
  '&:last-child': {
    marginBottom: 0,
  },
}

const label: SxProps = {
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  fontWeight: 400,
  color: 'rgba(126, 139, 166, 1)',
}

const value = {
  fontFamily: 'Dosis',
  fontSize: '16px',
  fontWeight: 600,
  color: 'rgba(28, 40, 66, 1)',
}

const styles = { tooltipContainer, rowContainer, label, value }

export default styles
