import React, { FC, useMemo } from 'react'

import VerticalTableBase from 'components/verticals/VerticalTableBase/VerticalTableBase'
import { VerticalTableCMPBaseProps } from 'components/verticals/VerticalTableBase/types'
import TableText from 'components/common/TableText'
import { MRT_ColumnDef } from 'material-react-table'
import { TABLE_TEXT_TYPES } from 'utils/types'
import { useBaseCellStyles } from 'components/verticals/VerticalTableBase/useBaseCellStyles'
import { NOT_AVAILABLE } from 'utils/constants'
import { EcommerceExtendedEntityForTable } from 'containers/EcommerceExtendedTablePage/types'
import { dotColors, getMagnitudeConfig, getRiskConfig } from 'components/ecommerceExtended/EcommerceExtendedTable/util'
import { SxProps } from '@mui/material'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Tooltip from 'components/Tooltip'
import {
  colorsByValues,
  EcomExtendedSellerRegStatusEnum,
  EcommerceExtendedOverviewEnums,
} from 'containers/EcommerceExtendedTablePage/utils'
import ColorStatusCell from 'components/common/ColorStatusCell'
import { useBaseColumnsV2 } from 'components/verticals/VerticalTableBase/useBaseColumnsV2'
import { getAbbreviatedNumberString } from 'components/Income/Income'
import RegistryNumberColumn from './RegistryNumberColumn'

const ColoredDot = ({ sx }: { sx: SxProps }) => {
  return <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', ...sx }}></Box>
}

const EcommerceExtendedTable: FC<VerticalTableCMPBaseProps<EcommerceExtendedEntityForTable>> = ({
  verticalEntities,
  fetchNextPage,
  isFetching,
  totalDBRowCount,
  totalFetched,
  sorting,
  setSorting,
  setMergedColumns,
  selectedColumns,
  allColumns,
}) => {
  const { profileNamesColumn, verifiedNameColumn, dataSourcesColumn, firstActivityColumn, tinColumn, caseIdColumn } =
    useBaseColumnsV2<EcommerceExtendedEntityForTable>({})
  const baseCellStyles = useBaseCellStyles()
  const revenueStyles = {
    muiTableHeadCellProps: {
      sx: { borderBottom: 'none' },
    },
    muiTableBodyCellProps: {
      sx: { ...baseCellStyles },
    },
    size: 150,
  }
  const columns = useMemo<MRT_ColumnDef<EcommerceExtendedEntityForTable>[]>(
    () => [
      profileNamesColumn,
      verifiedNameColumn,
      tinColumn,
      {
        id: 'overviewDetails.cartCheckStatus',
        header: 'Tax Collection',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Tax Collection</TableText>,
        accessorFn: item => {
          const collectionStatus = item?.overviewDetails?.cartCheckStatus

          if (!collectionStatus || !EcommerceExtendedOverviewEnums?.[collectionStatus]) {
            return <TableText>{NOT_AVAILABLE}</TableText>
          }

          const color = colorsByValues.taxCollectionStatus?.[collectionStatus]
          const label = EcommerceExtendedOverviewEnums[collectionStatus]
          return <ColorStatusCell label={label} color={color} />
        },
        ...revenueStyles,
        size: 180,
      },
      {
        id: 'overviewDetails.risk.registrationStatus',
        header: 'Seller Registration Status',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Seller Reg. Status</TableText>,
        muiTableBodyCellProps: {
          sx: baseCellStyles,
        },
        size: 250,
        accessorFn: item => {
          const regStatus = item?.overviewDetails?.risk?.registrationStatus
          if (!regStatus) {
            return <TableText>{item?.overviewDetails?.risk?.registrationStatus || NOT_AVAILABLE}</TableText>
          }

          const color = colorsByValues.sellerRegistryStatus?.[regStatus]
          const label = EcomExtendedSellerRegStatusEnum?.[regStatus]
          return <ColorStatusCell label={label} color={color} />
        },
      },
      {
        id: 'overviewDetails.registry',
        header: 'Seller Registration Number',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Seller Registration Number</TableText>,
        muiTableBodyCellProps: {
          sx: baseCellStyles,
        },
        size: 250,
        accessorFn: item => <RegistryNumberColumn registry={item?.overviewDetails?.registry || []} />,
      },
      {
        id: 'overviewDetails.risk.score',
        header: 'IVIX RISK',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>IVIX Risk</TableText>,
        muiTableBodyCellProps: {
          sx: baseCellStyles,
        },
        accessorFn: item => {
          if (item?.overviewDetails?.risk?.score === undefined || item?.overviewDetails?.risk?.score === null) {
            return <TableText>{NOT_AVAILABLE}</TableText>
          }

          const score = item.overviewDetails.risk.score
          const riskConfig = getRiskConfig(score)

          return (
            <Stack direction='row' alignItems='center' gap={1}>
              <ColoredDot sx={{ bgcolor: riskConfig?.color }} />
              <TableText>{riskConfig?.label || NOT_AVAILABLE}</TableText>
            </Stack>
          )
        },
      },
      {
        id: 'overviewDetails.magnitude.score',
        header: 'IVIX MAGNITUDE',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>IVIX Magnitude</TableText>,
        muiTableBodyCellProps: {
          sx: baseCellStyles,
        },
        accessorFn: item => {
          if (
            item?.overviewDetails?.magnitude?.score === undefined ||
            item?.overviewDetails?.magnitude?.score === null
          ) {
            return <TableText>{NOT_AVAILABLE}</TableText>
          }

          const score = item.overviewDetails.magnitude.score
          const magnitudeConfig = getMagnitudeConfig(score)

          return (
            <Stack direction='row' alignItems='center' gap={1}>
              <ColoredDot sx={{ bgcolor: magnitudeConfig?.color }} />
              <TableText>{magnitudeConfig?.label || NOT_AVAILABLE}</TableText>
            </Stack>
          )
        },
      },
      dataSourcesColumn,
      firstActivityColumn,
      {
        id: 'overviewDetails.shopsCategories',
        header: 'Shop Categories',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Shop Category</TableText>,
        muiTableBodyCellProps: {
          sx: baseCellStyles,
        },
        accessorFn: item => {
          const categories = item?.overviewDetails?.shopsCategories || []
          const firstCategory = categories[0] || NOT_AVAILABLE
          const remainingCount = categories.length - 1

          return (
            <TableText>
              {firstCategory}
              {remainingCount > 0 && (
                <Tooltip theme='light' title={categories.slice(1).join(', ')}>
                  <span> +{remainingCount}</span>
                </Tooltip>
              )}
            </TableText>
          )
        },
      },
      {
        id: 'overviewDetails.risk.physicalNexus.address',
        header: 'Address Nexus',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Physical Nexus: Address</TableText>,
        muiTableBodyCellProps: {
          sx: baseCellStyles,
        },
        accessorFn: item => {
          const hasAddressNexus = item?.overviewDetails?.risk?.physicalNexus?.address
          const color = hasAddressNexus ? dotColors.green : dotColors.red
          return (
            <Stack direction='row' alignItems='center' gap={1}>
              <ColoredDot sx={{ bgcolor: color }} />
              <TableText>{hasAddressNexus ? 'True' : 'False'}</TableText>
            </Stack>
          )
        },
      },
      {
        id: 'overviewDetails.risk.physicalNexus.employees',
        header: 'Employees Nexus',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Physical Nexus: Employees</TableText>,
        muiTableBodyCellProps: {
          sx: baseCellStyles,
        },
        accessorFn: item => {
          const hasEmployeesNexus = item?.overviewDetails?.risk?.physicalNexus?.employees
          const color = hasEmployeesNexus ? dotColors.green : dotColors.red
          return (
            <Stack direction='row' alignItems='center' gap={1}>
              <ColoredDot sx={{ bgcolor: color }} />
              <TableText>{hasEmployeesNexus ? 'True' : 'False'}</TableText>
            </Stack>
          )
        },
      },
      {
        id: 'overviewDetails.magnitude.numberOfShops',
        header: 'Number of Shops',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Number Of Shops</TableText>,
        muiTableBodyCellProps: {
          sx: baseCellStyles,
        },
        accessorFn: item => <TableText>{item?.overviewDetails?.magnitude?.numberOfShops || NOT_AVAILABLE}</TableText>,
      },
      {
        id: 'overviewDetails.registry.addresses',
        header: 'Addresses',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Addresses</TableText>,
        muiTableBodyCellProps: {
          sx: baseCellStyles,
        },
        accessorFn: item => {
          const registry = item?.overviewDetails?.registry || []
          const addresses = registry.flatMap(reg => reg.addresses.map(addressObj => addressObj.value))
          const firstAddress = addresses[0] || NOT_AVAILABLE
          const remainingCount = addresses.length - 1

          return (
            <TableText>
              {firstAddress}
              {remainingCount > 0 && (
                <Tooltip
                  theme='transparent'
                  html={
                    <Box
                      sx={{
                        height: '300px',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': { width: '8px' },
                        '&::-webkit-scrollbar-thumb': { backgroundColor: '#888', borderRadius: '4px' },
                        '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#555' },
                      }}
                    >
                      {addresses.slice(1).map(address => (
                        <Box mr='10px' sx={{ wordBreak: 'keep-all' }} key={address}>
                          {address}
                        </Box>
                      ))}
                    </Box>
                  }
                >
                  <span style={{ fontWeight: 'bold' }}> +{remainingCount}</span>
                </Tooltip>
              )}
            </TableText>
          )
        },
      },
      {
        id: 'overviewDetails.magnitude.rank',
        header: 'Website Ranking',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Website Ranking</TableText>,
        muiTableBodyCellProps: {
          sx: baseCellStyles,
        },
        accessorFn: item => <TableText>{item?.overviewDetails?.magnitude?.rank || NOT_AVAILABLE}</TableText>,
      },
      {
        id: 'totals.numberOfEmployees',
        header: 'Number of Employees',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Number Of Employees</TableText>,
        muiTableBodyCellProps: {
          sx: baseCellStyles,
        },
        accessorFn: item => <TableText>{item?.totals?.numberOfEmployees || NOT_AVAILABLE}</TableText>,
      },
      {
        id: 'overviewDetails.magnitude.totalTraffic3Months',
        header: 'Current Monthly Web Traffic',
        Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Current Monthly Web Traffic</TableText>,
        size: 300,
        muiTableBodyCellProps: {
          sx: baseCellStyles,
        },
        accessorFn: item => {
          const totalTraffic3Months = item?.overviewDetails?.magnitude?.totalTraffic3Months
          const monthlyTraffic = totalTraffic3Months
            ? getAbbreviatedNumberString(totalTraffic3Months / 3)
            : NOT_AVAILABLE
          return <TableText>{monthlyTraffic}</TableText>
        },
      },
      caseIdColumn,
    ],
    [],
  )

  return (
    <VerticalTableBase<EcommerceExtendedEntityForTable>
      columns={columns}
      items={verticalEntities}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      totalFetched={totalFetched}
      totalDBRowCount={totalDBRowCount}
      sorting={sorting}
      setSorting={setSorting}
      setMergedColumns={setMergedColumns}
      selectedColumns={selectedColumns}
      allColumns={allColumns}
      avoidBaseColumns={true}
    />
  )
}

export default EcommerceExtendedTable
