import React, { ReactNode, FC } from 'react'
import Box from '@mui/system/Box'
import { SxProps } from '@mui/material'
import styles from './styles'

interface IWhiteBoxWrapper {
  children?: ReactNode
  sx?: SxProps
  className?: string
}

const WhiteBoxWrapper: FC<IWhiteBoxWrapper> = ({ children, sx = {}, className }) => {
  return (
    <Box
      sx={{
        ...styles.container,
        ...sx,
      }}
      className={className}
    >
      {children}
    </Box>
  )
}

export default WhiteBoxWrapper
