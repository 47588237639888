import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { CartCheckProduct } from 'store/verticals/types'
import { useCopyToClipboard } from 'containers/VerticalPage/common/hooks'
import Icon from 'components/Icon'
import styles from './ProductsByShop.module.scss'
import sxStyles from 'containers/VerticalPage/ecommerce-extended/ProductsByShopTooltip/styles'
import { normalizeUrlProtocol } from 'containers/VerticalPage/common/utils'

interface ProductItemProps {
  product: CartCheckProduct
  index: number
}

const ProductItem = ({ product, index }: ProductItemProps) => {
  const { handleCopy, isLinkCopied } = useCopyToClipboard()

  return (
    <Stack
      component='li'
      key={product.productLink + index}
      sx={{
        ...sxStyles.ProductItemContainer,
        borderTop: index === 0 ? 'none' : '1px solid #DAD8DF',
        pt: index === 0 ? 0 : '12px',
        mt: index === 0 ? 0 : '12px',
      }}
    >
      <Box
        component='a'
        href={normalizeUrlProtocol(product.productLink)}
        target='_blank'
        rel='noreferrer'
        sx={sxStyles.ProductItemLink}
      >
        {!product?.title || product.title === 'Default Title' ? `Product ${index + 1}` : product.title}
      </Box>

      {isLinkCopied ? (
        <Icon name='done' size='regular' />
      ) : (
        <button onClick={() => handleCopy(normalizeUrlProtocol(product.productLink))} className={styles.copyBtn}>
          <Icon name='copy-gray' size='medium' svgClassName={styles.copyIcon} className={styles.copyIcon} />
        </button>
      )}
    </Stack>
  )
}

export default ProductItem
