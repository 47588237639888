import React from 'react'

const ExpandIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.66406 7.41669C6.41406 7.41669 6.2474 7.33335 6.08073 7.16669L1.91406 3.08335C1.58073 2.75002 1.58073 2.25002 1.91406 1.91669C2.2474 1.58335 2.7474 1.58335 3.08073 1.91669L7.2474 6.00002C7.58073 6.33335 7.58073 6.83335 7.2474 7.16669C7.08073 7.41669 6.91406 7.41669 6.66406 7.41669Z'
        fill='#2257C5'
      />
      <path
        d='M2.4974 18.3333C2.2474 18.3333 2.08073 18.25 1.91406 18.0833C1.58073 17.75 1.58073 17.25 1.91406 16.9167L6.08073 12.75C6.41406 12.4167 6.91406 12.4167 7.2474 12.75C7.58073 13.0833 7.58073 13.5833 7.2474 13.9167L3.08073 18.0833C2.91406 18.25 2.7474 18.3333 2.4974 18.3333Z'
        fill='#2257C5'
      />
      <path
        d='M17.4948 18.3333C17.2448 18.3333 17.0781 18.25 16.9115 18.0833L12.8281 13.9167C12.4948 13.5833 12.4948 13.0833 12.8281 12.75C13.1615 12.4167 13.6615 12.4167 13.9948 12.75L18.0781 16.9167C18.4115 17.25 18.4115 17.75 18.0781 18.0833C17.9115 18.25 17.7448 18.3333 17.4948 18.3333Z'
        fill='#2257C5'
      />
      <path
        d='M13.3333 7.41669C13.0833 7.41669 12.9167 7.33335 12.75 7.16669C12.4167 6.83335 12.4167 6.33335 12.75 6.00002L16.9167 1.91669C17.25 1.58335 17.75 1.58335 18.0833 1.91669C18.4167 2.25002 18.4167 2.75002 18.0833 3.08335L13.9167 7.16669C13.75 7.41669 13.5833 7.41669 13.3333 7.41669Z'
        fill='#2257C5'
      />
      <path
        d='M17.4974 7.08335C16.9974 7.08335 16.6641 6.75002 16.6641 6.25002V3.33335H13.7474C13.2474 3.33335 12.9141 3.00002 12.9141 2.50002C12.9141 2.00002 13.2474 1.66669 13.7474 1.66669H17.4974C17.9974 1.66669 18.3307 2.00002 18.3307 2.50002V6.25002C18.3307 6.75002 17.9974 7.08335 17.4974 7.08335Z'
        fill='#2257C5'
      />
      <path
        d='M17.4974 18.3334H13.7474C13.2474 18.3334 12.9141 18 12.9141 17.5C12.9141 17 13.2474 16.6667 13.7474 16.6667H16.6641V13.75C16.6641 13.25 16.9974 12.9167 17.4974 12.9167C17.9974 12.9167 18.3307 13.25 18.3307 13.75V17.5C18.3307 18 17.9974 18.3334 17.4974 18.3334Z'
        fill='#2257C5'
      />
      <path
        d='M6.2474 18.3334H2.4974C1.9974 18.3334 1.66406 18 1.66406 17.5V13.75C1.66406 13.25 1.9974 12.9167 2.4974 12.9167C2.9974 12.9167 3.33073 13.25 3.33073 13.75V16.6667H6.2474C6.7474 16.6667 7.08073 17 7.08073 17.5C7.08073 18 6.7474 18.3334 6.2474 18.3334Z'
        fill='#2257C5'
      />
      <path
        d='M2.4974 7.08335C1.9974 7.08335 1.66406 6.75002 1.66406 6.25002V2.50002C1.66406 2.00002 1.9974 1.66669 2.4974 1.66669H6.2474C6.7474 1.66669 7.08073 2.00002 7.08073 2.50002C7.08073 3.00002 6.7474 3.33335 6.2474 3.33335H3.33073V6.25002C3.33073 6.75002 2.9974 7.08335 2.4974 7.08335Z'
        fill='#2257C5'
      />
    </svg>
  )
}

export default ExpandIcon
