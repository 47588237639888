import React from 'react'
import GenericHeadingLink from 'components/common/GenericHeadingLink'
import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

interface VerticalCardHeadingLinkProps {
  href: string
  profileName: string
  sx: SxProps<Theme>
}

const VerticalCardHeadingLink = ({ href, profileName, sx }: VerticalCardHeadingLinkProps) => {
  return (
    <GenericHeadingLink href={href} headingSx={sx}>
      {profileName?.length >= 25 ? `${profileName?.slice(0, 25)}...` : profileName}
    </GenericHeadingLink>
  )
}

export default VerticalCardHeadingLink
