const ProductByShop = {
  width: '360px',
  height: '400px',
  overflow: 'hidden',
}

const TooltipTitle = {
  fontSize: '1.25rem',
  fontWeight: '600',
  lineHeight: '2rem',
  textAlign: 'start',
  p: '12px 12px 12px 0',
}

const ProductsList = {
  pr: '12px',
  pb: '12px',
  mt: '16px',
  overflowY: 'scroll',
}

const NoSelectedShopText = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const ProductItemContainer = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const ProductItemLink = {
  fontFamily: 'Source Sans Pro',
  fontSize: '1rem',
  lineHeight: '24px',
  overflow: 'hidden',
  width: '80%',
  maxHeight: '24px',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  textAlign: 'start',
}

const styles = {
  ProductByShop,
  TooltipTitle,
  ProductsList,
  NoSelectedShopText,
  ProductItemContainer,
  ProductItemLink,
}

export default styles
