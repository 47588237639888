import React from 'react'
import WhiteBoxWrapper from '../WhiteBoxWrapper/WhiteBoxWrapper'
import styles from './ContactInfo.scss'
import Box from '@mui/material/Box'
import SocialBlock from '../SocialBlock/SocialBlock'
import TabScrolledContent from '../TabScrolledContent/TabScrolledContent'
import { ContactInfo } from 'store/verticals/types'
import { socialLinksData } from './ContactInfo.config'

const phonesUrl = require('../Assets/phones.svg') as string
const emailsUrl = require('../Assets/emails.svg') as string
const addressesUrl = require('../Assets/addresses.svg') as string

interface contactInfoProps {
  contactInfo?: ContactInfo
}

const ContactInfo = ({ contactInfo }: contactInfoProps) => {
  if (!contactInfo) return null

  const { weblinks, phones, emails, addresses } = contactInfo

  const socialLinks = Object.entries(weblinks).flatMap(([key, links]) =>
    socialLinksData[key]
      ? links.map(link => ({
          logo: socialLinksData[key].logo,
          text: link,
          backgroundColor: socialLinksData[key].backgroundColor,
          textColor: socialLinksData[key].textColor,
        }))
      : [],
  )

  return (
    <TabScrolledContent sxProps={{ height: '100%', overflow: 'hidden' }}>
      <WhiteBoxWrapper className={styles.contactInfoContainer} sx={{ p: 3, height: '100%', overflow: 'auto' }}>
        <Box>
          <h2>WebLinks</h2>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '20px',
              borderBottom: '1px solid #EAECF0',
              paddingTop: 2,
              paddingBottom: 3,
            }}
          >
            {socialLinks.map((link, index) => (
              <SocialBlock
                key={index}
                logo={link.logo}
                text={link.text}
                backgroundColor={link.backgroundColor}
                textColor={link.textColor}
              />
            ))}
          </Box>
        </Box>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
            <Box sx={{ width: '100%' }}>
              <h2 style={{ paddingBottom: 8 }}>Phones</h2>
              <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                {phones?.map((phone, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img src={phonesUrl} />
                    {phone}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={{ width: '100%' }}>
              <h2 style={{ paddingBottom: 8 }}>E-mails</h2>
              <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                {emails?.map((email, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img src={emailsUrl} />
                    {email}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={{ width: '100%' }}>
              <h2 style={{ paddingBottom: 8 }}>Addresses</h2>
              <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                {addresses?.map((address, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img src={addressesUrl} />
                    {address}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </WhiteBoxWrapper>
    </TabScrolledContent>
  )
}
export default ContactInfo
