const ContentContainer = {
  gridColumn: '1 / 2',
  gridRow: '1 / 2',
  display: 'grid',
  gridTemplateColumns: '32% minmax(0, 1fr)',
  gridTemplateRows: 'auto',
  gap: { md: '12px 18px', lg: '12px 22px', xl: '18px 25px', xxl: '28px', xxxl: '30px' },
  padding: '0 16px 0 16px',
}

const styles = {
  ContentContainer,
}

export default styles
