import React from 'react'
import GenericCard from 'components/common/GenericCard'
import GenericCardHeader from 'components/common/GenericCardHeader'
import GenericText from 'components/common/GenericText'
import GenericLink from 'components/common/GenericLink'
import EntityCardDetails from 'components/EntityCard/EntityCardDetails'
import GenericHeading from 'components/common/GenericHeading'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import moment from 'moment'
import { extractRootDomainNoExt } from '../../containers/NFT/specs'
import { allowedSources, originalSourceToImageMap, SOURCE_GLOBE } from '../BusinessSource/specs'
import { NOT_AVAILABLE } from 'utils/constants'
import styles from './EntityCard.scss'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import sxStyles from './styles'

const markerIcon: string = require('./images/marker.svg')
const cakeIcon: string = require('./images/cake.svg')
const briefcaseIcon: string = require('./images/briefcase.svg')

interface EntityCardProps {
  tin?: string
  name: string
  image: string
  location: string
  birthday: string
  profileUrls?: string[]
}

const EntityCard = ({ tin, name, image, profileUrls = [], location, birthday }: EntityCardProps) => {
  const theme = useTheme()

  const getSourceSrc = (url: string) =>
    allowedSources.includes(extractRootDomainNoExt(url))
      ? originalSourceToImageMap[extractRootDomainNoExt(url)]
      : originalSourceToImageMap[SOURCE_GLOBE]

  return (
    <GenericCard sx={{ gridColumn: '1 / 2' }} className={styles.entityCard}>
      <GenericCardHeader
        sx={{ bgcolor: 'secondary.main' }}
        title={
          <Stack direction='row' spacing={{ md: 1, xxxl: 2 }} alignItems='center' sx={sxStyles.HeaderTitle}>
            <img src={briefcaseIcon} alt='TIN' className={styles.tinIcon} />
            <GenericText sx={sxStyles.TinLabel}>TIN:</GenericText>
            <GenericText sx={sxStyles.TinValue}>{tin || NOT_AVAILABLE}</GenericText>
          </Stack>
        }
      />
      <CardContent sx={sxStyles.CardBody}>
        <Stack direction='row'>
          <Avatar sx={sxStyles.Avatar} aria-label='Avatar'>
            {image ? <CardMedia component='img' image={image} alt='Avatar' /> : <BusinessCenterIcon />}
          </Avatar>
          <Box
            position='relative'
            sx={{ ...sxStyles.CardContent, alignSelf: !location && !birthday ? 'center' : 'flex-start' }}
          >
            <GenericHeading sx={sxStyles.EntityName}>{name}</GenericHeading>
            <Stack direction='row' spacing={1} sx={sxStyles.ProfilUrls}>
              {profileUrls?.map(url => (
                <GenericLink
                  key={url}
                  href={url.replace(/\/$/, '')}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  <img className={styles.sourceIcon} src={getSourceSrc(url)} alt='Profile' width={14} height={14} />
                </GenericLink>
              ))}
            </Stack>
            <Box className='details' sx={{ color: theme.palette.secondary.main }}>
              {location && <EntityCardDetails svgIcon={markerIcon} value={location} />}
              {birthday && (
                <EntityCardDetails
                  svgIcon={cakeIcon}
                  value={birthday ? moment(birthday).format('MMM. yyyy') : NOT_AVAILABLE}
                />
              )}
            </Box>
          </Box>
        </Stack>
      </CardContent>
    </GenericCard>
  )
}
export default EntityCard
