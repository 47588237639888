import React, { useState } from 'react'
import Box from '@mui/material/Box'
import styles from './RegionTooltip.scss'
import { ReactSVG } from 'react-svg'
import { Business } from '../LeafletMap'
import Icon from 'components/Icon'
import { tabsIds } from 'containers/VerticalPage/ecommerce-extended/Details/utils'
import { normalizeUrlProtocol } from 'containers/VerticalPage/common/utils'
import Divider from '@mui/material/Divider'

interface RegionTooltipProps {
  displayName: string
  countryCode: string
  stateCode: string
  employees: number[]
  businesses: Business[]
  setActiveTab?: (tab: number) => void
  setEmployeeIds?: (employeeIds: number[]) => void
}

const RegionTooltip = ({
  displayName,
  countryCode,
  stateCode,
  employees,
  businesses,
  setActiveTab,
  setEmployeeIds,
}: RegionTooltipProps) => {
  const [visibleCount, setVisibleCount] = useState(5)

  const handleShowMore = () => {
    if (visibleCount >= businesses.length) {
      setVisibleCount(5)
    } else {
      setVisibleCount(prevCount => prevCount + 5)
    }
  }

  const handleViewEmployees = () => {
    if (setEmployeeIds) {
      setEmployeeIds(employees)
    }

    if (setActiveTab) {
      setActiveTab(tabsIds.EMPLOYEES)
    }
  }

  return (
    <Box className={styles.regionTooltip}>
      <Box className={styles.tooltipHeader}>
        <div>
          <ReactSVG
            src={require(`../assets/${stateCode ? `stateFlags/${stateCode}` : `flags/${countryCode}`}.svg`)}
            className={styles.flagIcon}
          />
          <p className={styles.regionName}>{displayName}</p>
        </div>
      </Box>
      <Box className={styles.tooltipSubHeader}>
        <p>{new Set(employees).size} Active Employees Found</p>
        {employees.length > 0 && (
          <button
            onClick={handleViewEmployees}
            className={styles.employeesButton}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '6px' }}
          >
            <p>View Employees</p>
            <Icon name='arrowRight' size='small' />
          </button>
        )}
      </Box>
      {businesses.length > 0 && <Divider sx={{ margin: '8px -16px' }} />}
      <Box
        style={
          businesses.length === 0
            ? { display: 'none' }
            : visibleCount >= businesses.length && businesses.length > 5
              ? { paddingRight: 3 }
              : {}
        }
        className={styles.tooltipBody}
      >
        <div className={styles.row}>
          <h3>Business Addresses Found</h3>
          <h3>Source</h3>
        </div>

        {businesses.slice(0, visibleCount).map((business, index) => (
          <div key={index} className={styles.row} style={{ borderBottom: '#dad8df 1px solid' }}>
            <p>
              {business.address.length > 35 ? (
                <span title={business.address}>{business.address.substring(0, 35)}...</span>
              ) : (
                business.address
              )}
            </p>
            <div
              style={{
                display: 'flex',
                gap: '6px',
                paddingBottom: '12px',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'max-content',
                marginRight: '6px',
              }}
            >
              {business.sources.map((source, sourceIndex) => (
                <Box component='a' href={normalizeUrlProtocol(source.uri)} key={sourceIndex} target='_blank'>
                  {(() => {
                    try {
                      return (
                        <ReactSVG
                          style={{ borderRadius: '2px' }}
                          src={require(`../assets/socials/${source.platform}.svg`)}
                        />
                      )
                    } catch (error) {
                      console.warn(`SVG for ${source.platform} not found:`, error)
                      return null
                    }
                  })()}
                </Box>
              ))}
            </div>
          </div>
        ))}

        {businesses.length > 5 && (
          <button type='button' className={styles.showMoreButton} onClick={handleShowMore}>
            {visibleCount >= businesses.length ? 'Hide Business Addresses' : '+5 Business Addresses'}
          </button>
        )}
      </Box>
    </Box>
  )
}

export default RegionTooltip
