import { SxProps } from '@mui/material'

const title: SxProps = {
  fontFamily: 'Dosis',
  fontSize: { md: '16px', lg: '18px', xl: '20px' },
  fontWeight: 600,
  textTransform: 'uppercase',
}

const searchBySelectorButton: SxProps = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
}

const muiTableContainerSxProps: SxProps = {
  table: {
    thead: {
      tr: {
        th: {
          padding: '10px',
          '.Mui-TableHeadCell-Content': {
            '.Mui-TableHeadCell-Content-Labels': {
              '.Mui-TableHeadCell-Content-Wrapper': {
                fontFamily: 'Source Sans Pro',
                fontSize: { md: '12px', lg: '13px', xl: '14px' },
                fontWeight: 700,
                color: '#7E8BA6',
              },
            },
          },
        },
      },
    },
    tbody: {
      tr: {
        td: {
          fontFamily: 'Source Sans Pro',
          fontSize: { md: '14px', lg: '15px', xl: '16px' },
          fontWeight: 400,
          color: '#1C2842',
          padding: '10px',
          textTransform: 'capitalize',
        },
        'td .MuiBox-root .MuiTypography-root': {
          fontFamily: 'Source Sans Pro',
          fontSize: { md: '14px', lg: '15px', xl: '16px' },
          fontWeight: 400,
          color: '#1C2842',
        },
      },
    },
  },
}

const styles = {
  title,
  searchBySelectorButton,
  muiTableContainerSxProps,
}

export default styles
