import React, { useMemo } from 'react'
import styles from './EcommerceExtendedTablePage.scss'
import VerticalSummaryPieChart from 'components/common/VerticalSummaryPieChart'
import { PieChartData } from 'utils/types'
import { IEcommerceExtendedSummary, SummaryTitles } from 'containers/EcommerceExtendedTablePage/types'
import { colorsByValues, EcomExtendedSellerRegStatusEnum } from 'containers/EcommerceExtendedTablePage/utils'
import { getAbbreviatedNumberString } from 'components/Income/Income'
import { NOT_AVAILABLE } from 'utils/constants'
import DetailedChartLegends from 'components/common/DetailedChartLegends'
import { getPercentage } from 'utils/getPercentage'
import GenericHeading from 'components/common/GenericHeading'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { useWindowSize } from 'hooks/useWindowSize'
import { stringToColor } from 'containers/OffshoreTablePage/BusinessSummary/utils'
import { PieChartContainer } from 'containers/EcommerceExtendedTablePage/styles'
import { VerticalSummaryLoader } from 'containers/VerticalPage/common/Loaders'
import { EcommerceExtendedOverviewEnums } from 'containers/EcommerceExtendedTablePage/utils'

interface EcommerceExtendedSummaryProps {
  summaryData: IEcommerceExtendedSummary
}

const EcommerceExtendedSummary = ({ summaryData }: EcommerceExtendedSummaryProps) => {
  const theme = useTheme()
  const { width } = useWindowSize()

  const getPieTitle = (totalAmount?: number) => {
    if (typeof totalAmount !== 'number') return NOT_AVAILABLE
    return getAbbreviatedNumberString(totalAmount)
  }

  const getLegendsData = (
    chartDataItems: PieChartData[],
    totalAmount: number,
    summaryKey: keyof IEcommerceExtendedSummary,
  ) => {
    if (!chartDataItems || totalAmount === undefined || totalAmount === null) {
      return null
    }

    const currentEnum =
      summaryKey === 'sellerRegistryStatus' ? EcomExtendedSellerRegStatusEnum : EcommerceExtendedOverviewEnums

    return chartDataItems.map(item => ({
      name: currentEnum?.[item?.name] || item?.name,
      percent: getPercentage(item?.value, totalAmount, 1),
      color: colorsByValues?.[summaryKey]?.[item?.name] || stringToColor(item?.name),
    }))
  }

  const getMoreResultLegend = (moreResultsCount: number) => {
    if (moreResultsCount === undefined || moreResultsCount === null) {
      return null
    }

    if (moreResultsCount < 1) {
      return null
    }

    return `+${getAbbreviatedNumberString(moreResultsCount)} more`
  }

  const pieSize = useMemo(() => {
    if (width <= (theme.breakpoints?.values?.lgPlus || 0)) {
      return {
        size: 110,
        innerRadius: 48,
        outerRadius: 53,
      }
    }

    return {
      size: 145,
      innerRadius: 66,
      outerRadius: 72,
    }
  }, [width])

  const summaries = useMemo(() => (summaryData ? Object.entries(summaryData) : null), [summaryData])

  if (!summaries) {
    return (
      <section className={styles.summaryContainer}>
        <VerticalSummaryLoader />
      </section>
    )
  }

  return (
    <section className={styles.summaryContainer}>
      {summaries &&
        summaries?.map(([key, value], index) => {
          const summaryKey = key as keyof IEcommerceExtendedSummary
          const summaryValue = value as IEcommerceExtendedSummary[keyof IEcommerceExtendedSummary]
          const optionsColors = summaryValue?.pieChartData?.map(datum => colorsByValues?.[summaryKey]?.[datum?.name])
          return (
            <Stack
              key={key}
              direction='row'
              sx={{
                bgcolor: theme.palette.secondary.main,
                width: `${100 / summaries.length}%`,
                borderTopLeftRadius: index === 0 ? '8px' : 'none',
                borderBottomLeftRadius: index === 0 ? '8px' : 'none',
                borderTopRightRadius: index === summaries.length - 1 ? '8px' : 'none',
                borderBottomRightRadius: index === summaries.length - 1 ? '8px' : 'none',
                ...PieChartContainer,
              }}
              className={styles.data}
            >
              <VerticalSummaryPieChart
                chartData={summaryValue?.pieChartData || []}
                dataKey={summaryKey}
                options={{
                  colors: optionsColors,
                  pieTitle: getPieTitle(summaryValue.total),
                  pieSubTitle: 'Total Entities',
                  pieSize,
                }}
              />
              <Stack>
                <GenericHeading
                  sx={{
                    textTransform: 'uppercase',
                    mb: 1,
                    fontSize: '1.25em',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  {SummaryTitles[summaryKey]}
                </GenericHeading>
                <DetailedChartLegends
                  legendsData={getLegendsData(summaryValue?.pieChartData, summaryValue?.total, summaryKey) || []}
                  moreResultsCount={getMoreResultLegend(summaryValue?.moreResultsCount)}
                />
              </Stack>
            </Stack>
          )
        })}
    </section>
  )
}

export default EcommerceExtendedSummary
