import { useMemo, RefObject, UIEvent } from 'react'
import { MRT_TableOptions, MRT_RowVirtualizer } from 'material-react-table'
import { useTheme } from '@mui/material/styles'

const useEmployeesTableOptions = (
  columns: any,
  verticalDocumentActivity: any[],
  isFetching: boolean,
  handleScroll: (event: UIEvent<HTMLDivElement>) => void,
  tableHeight: number | undefined,
  rowVirtualizerInstanceRef: RefObject<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>,
  muiTableContainerSxProps = {},
): MRT_TableOptions<any> => {
  const theme = useTheme()
  const margins = 100

  return useMemo(
    () => ({
      columns,
      data: verticalDocumentActivity || [],
      state: {
        isLoading: isFetching && !verticalDocumentActivity?.length,
      },
      enableRowSelection: false,
      enableColumnOrdering: false,
      enableGlobalFilter: false,
      enableTopToolbar: false,
      enablePagination: false,
      enableSorting: false,
      manualSorting: true,
      enableBottomToolbar: false,
      enableColumnActions: false,
      enableExpandAll: true,
      enableExpanding: true,
      initialState: { expanded: true },
      rowVirtualizerInstanceRef: rowVirtualizerInstanceRef,
      rowVirtualizerOptions: { overscan: 4 },
      layoutMode: 'grid-no-grow' as const,
      muiTableContainerProps: {
        onScroll: handleScroll,
        sx: {
          mt: '20px',
          flex: 1,
          height: (tableHeight || 0) - margins,
          ...theme.mixins.customScrollBar(),
          '& .MuiTable-root': {
            borderCollapse: 'collapse',
            borderSpacing: 0,
            '& th': {
              borderBottom: 'none',
              borderRight: 'none',
            },
            '& td': {
              borderRight: 'none',
              fontFamily: 'Source Sans Pro',
              fontSize: { md: '14px', lg: '15px', xl: '16px' },
              fontWeight: '400',
              lineHeight: { md: '18px', lg: '20px', xl: '24px' },
              textAlign: 'left',
              color: '#1C2842',
            },
          },
          ...muiTableContainerSxProps,
        },
      },
      muiTablePaperProps: {
        sx: {
          borderRadius: 0,
          flex: 1,
          boxShadow: 'none',
          ...theme.mixins.customScrollBar(),
        },
      },
    }),
    [columns, verticalDocumentActivity, handleScroll, theme.mixins, tableHeight, isFetching],
  )
}

export default useEmployeesTableOptions
