import React from 'react'
import { camelCase } from 'lodash'
import BusinessSource from 'components/BusinessSource'
import styles from './BusinessDataSources.scss'

export interface IBusinessType {
  name: string
}

export interface IBusiness {
  dataSources: Array<string>
  urls?: Array<string>
}

function BusinessDataSources({ dataSources, urls }: IBusiness) {
  const sourceClick = (index: number) => {
    window.open(urls?.[index], '_blank')
  }

  return (
    <ul className={styles.sources}>
      {dataSources.map((source: string, index: number) => (
        <li
          key={index}
          className={urls?.[index] ? styles.clickable : ''}
          onClick={() => (urls?.[index] ? sourceClick(index) : null)}
        >
          <BusinessSource source={camelCase(source)} isDisabled />
        </li>
      ))}
    </ul>
  )
}

export default BusinessDataSources
