import { Theme } from '@mui/material/styles'
import { CSSProperties } from 'react'

const PlatformIcon: CSSProperties = {
  objectFit: 'cover',
  border: '1px solid white',
  borderRadius: '50%',
}

const HeaderContainer = {
  width: { md: '26px', lg: '24px', xl: '30px', xxl: '35px', xxxl: '45px' },
  height: { md: '26px', lg: '24px', xl: '30px', xxl: '35px', xxxl: '45px' },
}
const CollapsedHeaderContainer = {
  width: '2.125rem',
  height: '2.125rem',
}

const ContentContainer = {
  padding: { md: '8px', lg: '12px', xl: '12px', xxl: '20px', xxxl: '32px' },
  '&:last-child': {
    paddingBottom: { md: '8px', lg: '10px', xl: '12px', xxl: '20px', xxxl: '32px' },
  },
}

const TopContent = (theme: Theme) => ({
  transform: 'translateX(-50%)',
  position: 'absolute',
  top: { md: '-35px', lg: '-40px', xl: '-50px', xxl: '-55px', xxxl: '-78px' },
  left: '50%',
  alignItems: 'center',
  width: `calc(100% - ${theme.spacing(1)})`,
})

const Avatar = {
  width: { md: '45px', lg: '50px', xl: '60px', xxl: '75px', xxxl: '115px' },
  height: { md: '45px', lg: '50px', xl: '60px', xxl: '75px', xxxl: '115px' },
  border: '1px solid white',
}

const CollapsedAvatar = {
  border: '1px solid white',
  width: '2.22rem',
  height: '2.22rem',
}

const HeadingLink = {
  textTransform: 'capitalized',
  fontSize: { md: '16px', lg: '18px', xl: '22px', xxl: '28px', xxxl: '46px' },
  fontWeight: 600,
}

const CollapsedHeadingLink = {
  ...HeadingLink,
  color: 'white',
  fontSize: '1.25em',
}

const styles = {
  PlatformIcon,
  HeaderContainer,
  CollapsedHeadingLink,
  CollapsedHeaderContainer,
  ContentContainer,
  TopContent,
  Avatar,
  CollapsedAvatar,
  HeadingLink,
}

export default styles
