import { SxProps } from '@mui/material'

export enum ContactInfoSections {
  weblinks = 'weblinks',
  phones = 'phones',
  emails = 'emails',
  addresses = 'addresses',
  wallets = 'wallets',
}

export type IrrelevantContactSections = Array<keyof typeof ContactInfoSections>

export enum FORMAT_HANDLERS {
  NumberValue = 'numberValue',
  FirstActivity = 'firstActivity',
  LastActivity = 'lastActivity',
  CartCheckStatus = 'cartCheck',
  ProductCount = 'productCount',
  AveragePrice = 'averagePrice',
}

// Shop
export enum SalesTaxCartStatuses {
  TAX_DETECTED = 'Tax Collection Verified',
  TAX_SECTION_NOT_DETECTED = 'No Tax Collection',
  TAX_SECTION_DETECTED = 'No Tax Collection',
  ERROR_FILL_IN_FORM = 'Cart Check Error',
  FAILURE = 'Cart Check Error',
}

export interface TooltipData {
  text: string
  iconSx: SxProps
}

// Seller
export enum SellerCartCheckStatus {
  CART_CHECK_ERROR = 'Cart Check Error For All Shops',
  TAX_COLLECTED_PROPERLY = 'Tax Collected For All Shops',
  MIX_TAX_COLLECTED = 'Mixed Tax Collection',
  TAX_NOT_COLLECTED = 'No Tax Collection For All Shops',
}
