type Colors = '#34C759' | '#FF3B30' | '#FF9500' | '#5AC8FA' | '#FFFFFF' | '#FFCC00'

const colors: Record<string, Colors> = {
  green: '#34C759',
  red: '#FF3B30',
  orange: '#FF9500',
  yellow: '#FFCC00',
  blue: '#5AC8FA',
  white: '#FFFFFF',
}

export interface ColorByValue {
  sellerRegistryStatus: { [key: string]: Colors[number] }
  taxCollectionStatus: { [key: string]: Colors[number] }
  riskScoreDistribution: { [key: string]: Colors[number] }
}

export const colorsByValues: ColorByValue = {
  sellerRegistryStatus: {
    Registered: colors.green,
    Unregistered: colors.red,
    LateRegistration: colors.orange,
  },
  taxCollectionStatus: {
    TAX_NOT_COLLECTED: colors.red,
    TAX_COLLECTED_PROPERLY: colors.green,
    CART_CHECK_ERROR: colors.blue,
    MIX_TAX_COLLECTED: colors.orange,
  },
  riskScoreDistribution: {
    Low: colors.green,
    Moderate: colors.yellow,
    High: colors.orange,
    Critical: colors.red,
  },
}

export enum EcommerceExtendedOverviewEnums {
  CART_CHECK_ERROR = 'Cart Check Error',
  TAX_COLLECTED_PROPERLY = 'Collecting',
  MIX_TAX_COLLECTED = 'Mixed',
  TAX_NOT_COLLECTED = 'Not Collecting',
}

export enum EcomExtendedSellerRegStatusEnum {
  Registered = 'Registered',
  Unregistered = 'Unregistered',
  LateRegistration = 'Late Reg.',
}
