// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LeafletMap__container--Ql9m6{height:calc(100% - 72px);border-radius:0 0 15px 15px}.LeafletMap__legendRow--_jEFv{display:flex;align-items:center;gap:8px;font-family:\"Source Sans Pro\";font-size:16px;color:#1c2842}.LeafletMap__customTooltip--S0Poe{background-color:#fff !important;border:1px solid #dad8df;border-radius:8px;padding:12px 16px;box-shadow:none !important;cursor:auto !important;color:#000}.LeafletMap__customTooltip--S0Poe::before{margin-top:-11px}", "",{"version":3,"sources":["webpack://./app/components/LeafletMap/LeafletMap.scss"],"names":[],"mappings":"AAAA,8BACE,wBAAA,CACA,2BAAA,CAGF,8BACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,6BAAA,CACA,cAAA,CACA,aAAA,CAGF,kCACE,gCAAA,CACA,wBAAA,CACA,iBAAA,CACA,iBAAA,CACA,0BAAA,CACA,sBAAA,CACA,UAAA,CAEA,0CACE,gBAAA","sourcesContent":[".container {\n  height: calc(100% - 72px);\n  border-radius: 0 0 15px 15px;\n}\n\n.legendRow {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  font-family: 'Source Sans Pro';\n  font-size: 16px;\n  color: #1c2842;\n}\n\n.customTooltip {\n  background-color: white !important;\n  border: 1px solid #dad8df;\n  border-radius: 8px;\n  padding: 12px 16px;\n  box-shadow: none !important;\n  cursor: auto !important;\n  color: black;\n\n  &::before {\n    margin-top: -11px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LeafletMap__container--Ql9m6",
	"legendRow": "LeafletMap__legendRow--_jEFv",
	"customTooltip": "LeafletMap__customTooltip--S0Poe"
};
export default ___CSS_LOADER_EXPORT___;
