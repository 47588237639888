import React from 'react'

const FacebookUrl = require('../Assets/facebook.svg') as string
const InstagramUrl = require('../Assets/instagram.svg') as string
const PinterestUrl = require('../Assets/pinterest.svg') as string
const WebUrl = require('../Assets/web.svg') as string
const YoutubeUrl = require('../Assets/youtube.svg') as string
const TiktokUrl = require('../Assets/tiktok.svg') as string
const XUrl = require('../Assets/x.svg') as string
const LinkedinUrl = require('../Assets/linkedin.svg') as string
const ShopifyUrl = require('../Assets/shopify.svg') as string
const EtsyUrl = require('../Assets/etsy.svg') as string
const LinktreeUrl = require('../Assets/linktree.svg') as string
const OpencorporatesUrl = require('../Assets/opencorporates.svg') as string
const GmapUrl = require('../Assets/gmaps.svg') as string

export const socialLinksData: { [key: string]: { logo: JSX.Element; backgroundColor: string; textColor: string } } = {
  website: {
    logo: <img src={WebUrl} />,
    backgroundColor: '#EDF1FF',
    textColor: '#2436BA',
  },
  facebook: {
    logo: <img src={FacebookUrl} />,
    backgroundColor: '#E2EBFF',
    textColor: '#1A56E5',
  },
  instagram: {
    logo: <img src={InstagramUrl} />,
    backgroundColor: '#FDEFF3',
    textColor: '#E1306C',
  },
  pinterest: {
    logo: <img src={PinterestUrl} />,
    backgroundColor: '#FEE9F2',
    textColor: '#E60023',
  },
  youtube: {
    logo: <img src={YoutubeUrl} />,
    backgroundColor: '#FFEBEB',
    textColor: '#FF0000',
  },
  tiktok: {
    logo: <img src={TiktokUrl} />,
    backgroundColor: '#E2F7F7',
    textColor: '#000000',
  },
  x: {
    logo: <img src={XUrl} />,
    backgroundColor: '#EBEBEB',
    textColor: '#000000',
  },
  twitter: {
    logo: <img src={XUrl} />,
    backgroundColor: '#EBEBEB',
    textColor: '#000000',
  },
  linkedin: {
    logo: <img src={LinkedinUrl} />,
    backgroundColor: '#E0EFF6',
    textColor: '#0077B5',
  },
  shopify: {
    logo: <img src={ShopifyUrl} />,
    backgroundColor: '#EEF5E1',
    textColor: '#5F8E3E',
  },
  etsy: {
    logo: <img src={EtsyUrl} />,
    backgroundColor: '#FEECE0',
    textColor: '#F56400',
  },
  linktree: {
    logo: <img src={LinktreeUrl} />,
    backgroundColor: '#E1FBE6',
    textColor: '#009E1C',
  },
  opencorporates: {
    logo: <img src={OpencorporatesUrl} />,
    backgroundColor: '#F7EDEF',
    textColor: '#9E1F35',
  },
  gmaps: {
    logo: <img src={GmapUrl} />,
    backgroundColor: '#F7EDEF',
    textColor: '#000000',
  },
}
