const container = {
  height: '100%',
  gap: '12px',
}

const breadcrumbsStyles = {
  padding: '16px 16px 0 16px',
  '.MuiBreadcrumbs-root': {
    '.MuiBreadcrumbs-ol': {
      '.MuiBreadcrumbs-li': {
        'a.MuiLink-root': {
          fontSize: { md: '14px', lg: '15px', xl: '16px' },
        },
      },
    },
  },
}

const styles = { container, breadcrumbsStyles }

export default styles
