export interface LineChartDatum {
  name: string
  value: number
}

export interface DetailedChartLegend {
  name: string
  percent: string
  color: string
}

export interface PieSize {
  size: number
  innerRadius: number
  outerRadius: number
}

export enum CollapsibleCarouselButtons {
  Collapsed = 'Expand All Revenue Sources',
  Expanded = 'Collapse All Revenue Sources',
}
